import { useImporterContext } from "../../contexts/ImporterContextProvider";

export const delimiterMap = {
  None: "",
  Space: " ",
  Comma: ",",
  Semicolon: ";",
  Dash: "-",
  Tab: "\t",
  Dot: ".",
};

export const useDeleteTemplateHeaderMatching = () => {
  const { headersMappings, setHeadersMappings } = useImporterContext();

  const deleteTemplateHeaderMatching = (templateColumnLabel) => {
    const newHeadersMappings = { ...headersMappings };
    newHeadersMappings[templateColumnLabel] = {
      matched_headers: [],
      delimiter: null,
    };
    setHeadersMappings(newHeadersMappings);
  };

  return { deleteTempleteHeaderMatching: deleteTemplateHeaderMatching };
};

export const useRowPreview = (combinedColumns, delimiter) => {
  const { uploadedData, headerRowIndex } = useImporterContext();

  const getRowPreview = (rowIndex) => {
    const uploadedHeadersRow = uploadedData[headerRowIndex];
    const row = uploadedData[headerRowIndex + rowIndex + 1];
    const rowIsEmpty = !row || (row.length === 0 && row[0] === "");

    if (combinedColumns.length === 0 || rowIsEmpty) return undefined;

    return combinedColumns
      .map((col) => {
        const columnIndex = uploadedHeadersRow.indexOf(col);
        return row[columnIndex];
      })
      .join(delimiter);
  };

  return { getRowPreview };
};
