import {
  AppResponse,
  BatchValidationErrors,
  ColumnMapping,
  Field,
  Record,
  RecordDataSet,
  SpreadsheetValidationErrors,
  TemplateHeader,
  ValueMapping,
} from "fuse-importer";
import { useEffect, useRef, useState } from "react";
import { FuseApi } from "../../Importer/common/FuseApi";
import { usePersistenceContext } from "../../Importer/common/Spreadsheet/PersistenceContextProvider";
import { sendRecordsToIntegrations } from "../../Importer/common/utils";
import { getTemplateFields, recordListToDataSet } from "../../Importer/data";
import { addToast } from "../../common";
import { useAppLoadingContext } from "../../common/AppLoadingContextProvider";
import {
  EnumFieldValueMatchings,
  HeaderMappings,
  useImporterContext,
} from "../contexts/ImporterContextProvider";

type ValidationIssues = {
  errors: BatchValidationErrors;
  warnings: BatchValidationErrors;
};

type PaginationResponse<T> = {
  data: T;
  page: number;
  per_page: number;
  current: number;
  previous: number;
  next: number;
  limit: number;
  total_pages: number;
  total_count: number;
};

type ApiValidationIssues = {
  errors: PaginationResponse<BatchValidationErrors>;
  warnings: PaginationResponse<BatchValidationErrors>;
};

const trackImportedRowsCount = async (
  fuseApi: FuseApi,
  templateSlug: string,
  rowCount: number
): Promise<any> => {
  try {
    await fuseApi.post(`/api/v1/importer/templates/${templateSlug}/imports`, {
      row_count: rowCount,
    });
  } catch (error) {
    const errorMessage =
      error?.response?.data?.error ||
      "There was an error submitting your dataset";
    throw new Error(errorMessage);
  }
};

const buildColumnMappingsFromHeaderMatchings = (
  headersMappings: HeaderMappings,
  templateHeaders: TemplateHeader[]
): ColumnMapping[] => {
  return templateHeaders
    .filter(
      (column) =>
        column.label &&
        headersMappings[column.label]?.matched_headers.length > 0
    )
    .map(
      (column): ColumnMapping => {
        const { matched_headers, delimiter } = headersMappings[column.label];
        return {
          matched_column_names: matched_headers,
          template_column_name: column.label,
          delimiter: delimiter,
        };
      }
    );
};

const buildValueMappingsFromHeaderValueMatchings = (
  enumFieldValueMatchings: EnumFieldValueMatchings
): ValueMapping[] => {
  const mappings = Object.values(enumFieldValueMatchings).map((column) => {
    const valuesMappings = [];
    for (let [key, value] of Object.entries(column)) {
      if (!value) {
        continue;
      }
      valuesMappings.push({
        matched_value: key,
        template_value: value,
      });
    }
    return valuesMappings;
  });
  return mappings.flat();
};

const saveMappedColumns = async (
  columnMappings: ColumnMapping[],
  valueMappings: ValueMapping[],
  fuseApi: FuseApi,
  templateSlug: string
): Promise<any> => {
  try {
    await fuseApi.patch(`/api/v1/importer/templates/${templateSlug}`, {
      column_mappings: columnMappings,
      value_mappings: valueMappings,
    });
  } catch (error) {
    throw new Error("There was an error trying to save column mappings");
  }
};

const getValidationIssuesFromAPI = async (
  fuseApi: FuseApi,
  import_slug: string,
  page = 0,
  perPage = 50000
): Promise<ApiValidationIssues> => {
  try {
    const result = await fuseApi.get(
      `/api/v1/importer/validation_issues?import_slug=${import_slug}&page=${page}&per_page=${perPage}`
    );
    return result.data;
  } catch (error) {
    throw new Error("Error while getting validation issues from API");
  }
};

const getImportWasSuccessful = (response: AppResponse) =>
  (!response.errors || !Object.keys(response.errors).length) &&
  (response.recordsToDisplay === undefined ||
    response.recordsToDisplay?.length === 0);

const filterAndMapWithValidationIssues = (
  dataSet: RecordDataSet,
  validationIssues: ValidationIssues
): Record[] => {
  const errors = validationIssues.errors || {};
  const warnings = validationIssues.warnings || {};
  const keys = new Set([...Object.keys(errors), ...Object.keys(warnings)]);
  return Array.from(keys).map((rId) => {
    const record = dataSet[rId];
    const backendErrors = errors[rId];
    const backendWarnings = warnings[rId];
    if (dataSet[rId] && dataSet[rId]._meta) {
      if (backendErrors) {
        dataSet[rId]._meta!.backendErrors = backendErrors;
      }
      if (backendWarnings) {
        dataSet[rId]._meta!.backendWarnings = backendWarnings;
      }
    }
    return record;
  }, []);
};

const checkWrongData = (
  dataSet: RecordDataSet,
  errors: SpreadsheetValidationErrors,
  reviewFields: Field[]
) => {
  const errorsKeys = Object.keys(errors);
  let highestRowIndex = 0;

  Object.values(dataSet).forEach((record) => {
    highestRowIndex = Math.max(highestRowIndex, record._meta.rowIndex);
  });

  for (const currErrorId of errorsKeys) {
    const allFieldsName = reviewFields.map((field) => field.name);
    const columnsOnError = Object.keys(errors[currErrorId]);

    columnsOnError.forEach((column) => {
      if (!allFieldsName.includes(column)) {
        addToast(`Invalid column "${column}"`, "error");
      }
    });

    if (+currErrorId > highestRowIndex) {
      addToast(`Invalid row id "${currErrorId}"`, "error");
    }
  }
};

export const useSubmitToServer = (setHasTransformedRecords) => {
  const {
    headersMappings,
    enumFieldValueMatchings,
    templateHeaders,
    integrations,
    templateSlug,
    fuseApi,
    importerOptions: { previewMode },
    onSubmit,
  } = useImporterContext();

  const {
    persistRecords,
    importer,
    persisting,
    resetImporter,
    setValidatingPersistence,
  } = usePersistenceContext();

  const { setIsLoadingApp } = useAppLoadingContext();

  const isSubmittingRef = useRef(false);
  const [importWasSuccessful, setImportWasSuccessful] = useState(false);
  const [appResponse, setAppResponse] = useState<AppResponse>(null);
  const fields = getTemplateFields({ templateHeaders });

  const [responseInvalidRecords, setResponseInvalidRecords] = useState<
    Record[]
  >([]);
  const [afterSubmitCallback, setAfterSubmitCallback] = useState(null);
  const [webhooksProcessing, setWebhooksProcessing] = useState(false);
  const [dataSet, setDataSet] = useState<RecordDataSet>();

  const setIsSubmitting = (value) => {
    isSubmittingRef.current = value;
  };

  // when webhooks have finished processing
  const onPersistenceToFuseFinished = async () => {
    try {
      if (importer.current?.status === "failed") {
        // if the import failed, we don't need to fetch validation issues
        return;
      }
      setValidatingPersistence(true);

      // get any errors from the fuse API that might have occurred via webhook
      const {
        errors = { data: {}, total_count: 0 },
        warnings = { data: {}, total_count: 0 },
      } = await getValidationIssuesFromAPI(fuseApi, importer?.current?.slug);
      const webhookValidationErrors = {
        errors: errors?.data ?? {},
        warnings: warnings?.data ?? {},
      };
      const hasWebhookErrors = errors.total_count || warnings.total_count;
      if (hasWebhookErrors) {
        setImportWasSuccessful(false);
        mergeValidationIssues(dataSet, webhookValidationErrors);
      }

      if (!hasWebhookErrors) {
        setImportWasSuccessful(true);
      }
      setWebhooksProcessing(false);
    } catch (error) {
      console.error(error);
      setImportWasSuccessful(false);
      setWebhooksProcessing(false);
    }
  };

  // when all submissions (webhook or importer hook) have finished
  const onSubmittingFinished = () => {
    setIsSubmitting(false);
    setIsLoadingApp(false);
    setHasTransformedRecords(false);

    // done! tell the app
    const invalidDataSet = recordListToDataSet(responseInvalidRecords, fields);
    afterSubmitCallback(invalidDataSet.dataSet);

    if (importer?.current.persistence && !importWasSuccessful) {
      // create a new import, as we cannot change the status of a submitted import back to "submitting"
      resetImporter();
    }
  };

  const onSubmitToServer = async (
    dataSet: RecordDataSet,
    submittableRecords: Record[],
    afterSubmitCallback: (dataSet: RecordDataSet) => void
  ) => {
    if (!dataSet || isSubmittingRef.current || !importer.current) return;

    setResponseInvalidRecords([]);
    setIsSubmitting(true);
    setAfterSubmitCallback(() => afterSubmitCallback);
    setWebhooksProcessing(false);
    setDataSet(dataSet);
    if (!importer?.current?.persistence) setIsLoadingApp(true);

    try {
      const dataSetValues = Object.values(dataSet);
      setResponseInvalidRecords(
        getInvalidRecords(dataSetValues, submittableRecords)
      );

      if (importer?.current?.persistence) {
        setWebhooksProcessing(true);
        // submit to our servers and the webhooks
        await persistRecords(submittableRecords, async () => {
          // at this point the records have been submitted
          // but the backend hasn't necessarily finished
          // processing them
        });
      } else {
        // send to parent application's onSubmit hook
        await submitToParentApplication(submittableRecords, dataSet);
      }

      if (!previewMode) {
        await handleImportOperations(submittableRecords);
      }
    } catch (error) {
      setIsLoadingApp(false);
      setIsSubmitting(false);
      console.error(error);
    }
  };

  const submitToParentApplication = async (submittableRecords, dataSet) => {
    // get the result from the parent application
    const applicationResponse = await onSubmit(submittableRecords);
    const isAppResponseSuccessful = getImportWasSuccessful(applicationResponse);

    if (!isAppResponseSuccessful) {
      const { errors = {} } = applicationResponse || {};

      mergeInvalidRecordsWithResponse(dataSet, applicationResponse);
      checkWrongData(dataSet, errors, fields);
    }

    mergeRecordsToDisplay(applicationResponse.recordsToDisplay);
    setImportWasSuccessful(isAppResponseSuccessful);
    setAppResponse(applicationResponse);
  };

  const getInvalidRecords = (
    dataSetValues: Record[],
    validRecords: Record[]
  ) => {
    return dataSetValues.filter(
      (v) => v._meta.isInvalid && !validRecords.includes(v)
    );
  };

  const mergeValidationIssues = (
    dataSet: RecordDataSet,
    validationIssues: ValidationIssues
  ) => {
    setResponseInvalidRecords((oldValue) => {
      const newData = [
        ...filterAndMapWithValidationIssues(dataSet, validationIssues),
        ...oldValue,
      ];
      return newData;
    });
  };

  const mergeInvalidRecordsWithResponse = (
    dataSet: RecordDataSet,
    applicationResponse: AppResponse
  ) => {
    const { errors = {} } = applicationResponse || {};
    mergeValidationIssues(dataSet, { errors, warnings: {} });
  };

  const mergeRecordsToDisplay = (recordsToDisplay: Record[]) => {
    if (!recordsToDisplay) return;

    setResponseInvalidRecords((oldValue) => [
      ...oldValue,
      ...recordsToDisplay.filter(
        (record) =>
          !oldValue.some(
            (invalidRecord) => invalidRecord._meta.id === record._meta.id
          )
      ),
    ]);
  };

  const handleImportOperations = async (validRecords: Record[]) => {
    const columnMappings = buildColumnMappingsFromHeaderMatchings(
      headersMappings,
      templateHeaders
    );
    const valueMappings = buildValueMappingsFromHeaderValueMatchings(
      enumFieldValueMatchings
    );

    if (!importer?.current.persistence) {
      // if we didn't persist the records, add the row count to the backend
      await trackImportedRowsCount(fuseApi, templateSlug, validRecords.length);
    }

    await sendRecordsToIntegrations(validRecords, integrations);
    await saveMappedColumns(
      columnMappings,
      valueMappings,
      fuseApi,
      templateSlug
    );
  };

  useEffect(() => {
    if (!isSubmittingRef.current) return;
    if (importer?.current.persistence && webhooksProcessing) return;
    if (!importer?.current.persistence && !appResponse) return;

    onSubmittingFinished();
  }, [appResponse, webhooksProcessing]);

  useEffect(() => {
    if (persisting || !webhooksProcessing) return;

    onPersistenceToFuseFinished();
  }, [persisting]);

  return {
    appResponse,
    isSubmitting: isSubmittingRef.current,
    setIsSubmitting,
    onSubmitToServer,
    importWasSuccessful,
    webhooksProcessing,
  };
};
