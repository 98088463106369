import { SpreadsheetField } from "..";
import { handleInputPaste } from "../../../../../../common";
import { HTMLTextAreaCSSProps } from "../../../../../../styled/utils";
import { FieldTypes, Record } from "fuse-importer";
import * as S from "./SpreadsheetInput.styles";
import { useSpreadsheetInput } from "./useSpreadsheetInput";

export type SpreadsheetTextAreaArgs = {
  name: string;
  record: Record;
  fieldType: FieldTypes;
  columnWidth?: string;
} & HTMLTextAreaCSSProps;

const parseValue = (value: string | number) => {
  const isFalsyButNotZero = !value && value !== 0;
  return isFalsyButNotZero ? "" : value;
};

const isFirefox =
  typeof navigator !== "undefined" && /firefox/i.test(navigator.userAgent);

export const SpreadsheetTextArea = ({
  name,
  record,
  fieldType,
  columnWidth,
  ...props
}: SpreadsheetTextAreaArgs) => {
  const {
    validationError,
    validationWarning,
    isHighlighted,
    defaultWidthAsNumber,
    expandedFieldWidth,
    isMultiline,
    fieldHeight,
    value,
    hasTransformedRecords,
    setValue,
    handleKeyDown,
    textAreaRef,
    _fieldContentWidth,
  } = useSpreadsheetInput(name, record, fieldType, columnWidth);

  const handleFocus = (e) => {
    if (isFirefox) {
      const val = e.target.value;
      e.target.value = "";
      e.target.value = val;
    }
  };

  return (
    <SpreadsheetField
      validationError={validationError}
      validationWarning={validationWarning}
      isHighlighted={isHighlighted}
      defaultFieldWidth={defaultWidthAsNumber}
      expandedFieldWidth={expandedFieldWidth}
      multiline={isMultiline}
      fieldHeight={fieldHeight}
      shouldExpandInput
    >
      <S.TextArea
        onPaste={handleInputPaste}
        autoComplete="off"
        hasError={!!validationError}
        hasWarning={!!validationWarning}
        value={parseValue(value)}
        onChange={({ target: { value } }) =>
          !hasTransformedRecords && setValue(value)
        }
        onKeyDown={handleKeyDown}
        disabledWhen={hasTransformedRecords}
        ref={textAreaRef}
        onFocus={handleFocus}
        {...props}
      />

      {record?._meta?.transformedRow?.[name]?.original &&
        hasTransformedRecords && (
          <S.SuffixText className="suffix" fieldWidth={_fieldContentWidth}>
            {record._meta.transformedRow[name].original}
          </S.SuffixText>
        )}
    </SpreadsheetField>
  );
};
