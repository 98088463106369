import styled, { ThemeProvider } from "styled-components";
import { Div, theme } from "../../styled";
import { Spinner } from "../../common";

const LoadingBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background: ${(props) => props.theme.colors.white};
  opacity: 0.6;
`;

const ModalContainer = styled(Div)`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

const LoaderContainer = styled(ModalContainer)`
  pointer-events: none;
`;

export const AppLoading = () => (
  <>
    <LoadingBackdrop />
    <LoaderContainer>
      <Div w={300}>
        <Div pRelative>
          <Spinner />
        </Div>
      </Div>
    </LoaderContainer>
  </>
);

export const AppLoadingWithTheme = () => (
  <ThemeProvider theme={theme}>
    <AppLoading />
  </ThemeProvider>
);
