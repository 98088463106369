import { useTranslation } from "react-i18next";
import { Div } from "../styled";
import { Banner } from "./Banner";
import { useImporterContext } from "./contexts/ImporterContextProvider";
import { baseUrls } from "./common";

export const TrialModeBanner = () => {
  const { t } = useTranslation("general");
  const { importerOptions } = useImporterContext();
  const env = importerOptions?.env || "production";

  const handleCta = () => {
    window.open(`${baseUrls[env]}/account/billing`, "_blank");
  };

  return (
    <Banner
      infoText={t("warnings_banner.trial_mode.info_text")}
      btnOnClick={handleCta}
      btnText={t("warnings_banner.trial_mode.button_text")}
    />
  );
};

export const InvalidSubmitFnBanner = () => {
  const { t } = useTranslation("general");

  const handleCta = () =>
    window.open(
      "https://fuse-docs.flatirons.com/accessing-data/incognito-mode#incognito-mode",
      "_blank"
    );

  return (
    <Banner
      infoText={t("warnings_banner.invalid_submit_fn.info_text")}
      btnOnClick={handleCta}
      btnText={t("warnings_banner.invalid_submit_fn.button_text")}
    />
  );
};

export const WarningsBanner = () => {
  const {
    inTrialMode,
    hasValidSubmitFn,
    isUsingPersistence,
  } = useImporterContext();

  const hasWarnings = inTrialMode || (!isUsingPersistence && !hasValidSubmitFn);

  if (!hasWarnings) return null;

  return (
    <Div data-cy="warningsBanner" flexColumn>
      {inTrialMode && <TrialModeBanner />}
      {!isUsingPersistence && !hasValidSubmitFn && <InvalidSubmitFnBanner />}
    </Div>
  );
};
