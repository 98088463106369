import { Button, Div, colors } from "fuse-shared-ui";
import styled from "styled-components";
import { useUserContext } from "../../../UserContextProvider";
import { ContentSectionContainer } from "../common/components/ContentSectionContainer";
import { handleIntercom } from "../common/utils/handleIntercom";

const EXACT_MONTH = 30;

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 64px;
  max-width: 800px;
`;

const RetentionPeriodExibition = styled(Div)`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.orange50};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  color: ${({ theme }) => theme.colors.gray500};
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
  padding: 20px 16px;
  user-select: none;
`;

const formatRetentionDays = (days: number) => {
  if (!days) return "N/A";

  const isExactMonth = days % EXACT_MONTH === 0;
  const months = days / EXACT_MONTH;

  if (isExactMonth) {
    return months === 1 ? `${months} month` : `${months} months`;
  }

  return days === 1 ? `${days} day` : `${days} days`;
};

const OrganizationSettings = () => {
  const {
    user: {
      active_organization: { data_retention_days },
    },
  } = useUserContext();

  const handleContactUs = () => {
    handleIntercom(
      "showNewMessage",
      "I am interested in changing my data retention period"
    );
  };

  return (
    <Wrapper>
      <Div data-cy="heading_text" h1>
        Company Settings
      </Div>

      <ContentSectionContainer title="Data retention settings">
        <Div data-cy="retention_period" body4 mt={24}>
          Total retention period
        </Div>
        <RetentionPeriodExibition>
          {formatRetentionDays(data_retention_days)}
        </RetentionPeriodExibition>
        <Div
          data-cy="retention_description"
          wbody1
          color={colors.gray700}
          mb={24}
        >
          After the data retention period, all imports and associated data will
          be deleted from Fuse. To change the data retention period for your
          organization, contact us.
        </Div>
        <Button data-cy="contact_us" onClick={handleContactUs}>
          Contact us
        </Button>
      </ContentSectionContainer>
    </Wrapper>
  );
};

export default OrganizationSettings;
