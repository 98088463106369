import { useImporterContext } from "../../../../Importer/contexts/ImporterContextProvider";
import { addMeta } from "../../../../Importer/data";
import { useEventManagerContext } from "../../../../common/EventManagerContextProvider";
import useFindAndReplace from "../../../../common/Table/TableActions/useFindAndReplace";
import { Record } from "fuse-importer";
import { useDataSetContext } from "../DataSetContextProvider";
import { useDuplicateDataContext } from "../DuplicateDataContextProvider";
import { useFilteredDataContext } from "../FilteredDataContextProvider";
import { useUndoRedoContext } from "../UndoRedoContextProvider";
import { useValidationContext } from "../ValidationContextProvider";
import { useBatchProcessing } from "./useBatchProcessing";
import { hasValueInAnyField } from "./utils";

export const useDataSetManipulation = () => {
  const {
    lastFilteredRecordId,
    setRecalculateFilters,
  } = useFilteredDataContext();
  const {
    getRecord,
    setRecord,
    dataSet,
    deleteRecord,
    emptyRecordIds,
    dataSetLength,
  } = useDataSetContext();
  const { emit, EVENTS } = useEventManagerContext();
  const {
    errors,
    warnings,
    calculateColumnErrorCounts,
    calculateColumnWarningCounts,
  } = useValidationContext();
  const { updateDuplicateValues } = useDuplicateDataContext();
  const { updateRecordAndRevalidate } = useValidationContext();
  const { processDataSetInBatches } = useBatchProcessing();
  const { replaceInRecord } = useFindAndReplace();
  const { trackRecordDeletion } = useUndoRedoContext();
  const { layoutRef } = useImporterContext();

  const addNewRecord = () => {
    const getNewId = () => {
      const ids = Object.keys(dataSet.current);
      const lastId = ids[ids.length - 1];
      return parseInt(lastId) + 1;
    };
    const newRow = addMeta({}, getNewId());
    setRecord(newRow._meta.id, newRow);
    return newRow._meta.id;
  };

  const appendNewRecordToSpreadsheet = () => {
    const _record = getRecord(lastFilteredRecordId.current);
    if (!hasValueInAnyField(_record)) {
      return;
    }

    addNewRecord();
    emit(EVENTS.ON_LAST_ROW_CHANGED);
  };

  const insertRecordsIntoSpreadsheet = async (records: Record[]) => {
    records.forEach((record) => {
      setRecord(record._meta.id, { _meta: record._meta });
    });

    await updateRecordAndRevalidate(records);

    setRecalculateFilters((prev) => !prev);

    emit(EVENTS.UPDATE_COLUMN_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ROW_COUNT);

    (layoutRef.current.querySelector(".input-content") as HTMLElement).focus();
  };

  const deleteRecordsFromSpreadsheet = (recordIds: string[]) => {
    trackRecordDeletion(
      recordIds,
      insertRecordsIntoSpreadsheet,
      (records: Record) => {
        const ids = records.map((r) => r._meta.id);
        deleteRecordsFromSpreadsheet(ids);
      }
    );

    const records = [];
    recordIds.forEach((recordId) => {
      records.push(getRecord(recordId));
      updateDuplicateValues(
        getRecord(recordId),
        {},
        false,
        updateRecordAndRevalidate
      );
      deleteRecord(recordId);
      calculateColumnErrorCounts(errors.current[recordId], {});
      calculateColumnWarningCounts(warnings.current[recordId], {});
      delete errors.current[recordId];
      delete warnings.current[recordId];
      emptyRecordIds.current.delete(recordId);
    });

    setRecalculateFilters((prev) => !prev);

    emit(EVENTS.UPDATE_COLUMN_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ROW_COUNT);

    // we may have a Boolean or Enum field in .input-content, so we access 'textarea'
    // if it's any other column type; or 'input' if it's one of those two
    const elementToFocus =
      layoutRef.current.querySelector(".input-content textarea") ||
      layoutRef.current.querySelector(".input-content input");

    (elementToFocus as HTMLElement).focus();
  };

  const findAndReplaceInSpreadsheet = async (
    searchText: string,
    replaceText: string,
    matchCase: boolean,
    useRegex: boolean
  ) => {
    const cellsWithText = [];
    await processDataSetInBatches(dataSet.current, (batch) => {
      for (const recordKey in batch) {
        const record = { ...batch[recordKey] };
        if (
          replaceInRecord(record, searchText, replaceText, matchCase, useRegex)
        ) {
          cellsWithText.push(record);
        }
      }
    });

    updateRecordAndRevalidate(cellsWithText);

    return cellsWithText;
  };

  return {
    addNewRecord,
    insertRecordsIntoSpreadsheet,
    appendNewRecordToSpreadsheet,
    deleteRecordsFromSpreadsheet,
    findAndReplaceInSpreadsheet,
  };
};
