export const datePatterns = [
  {
    value: "MM/dd/yyyy",
    label: "MM/dd/yyyy (e.g. 08/31/2023)",
  },
  {
    value: "MMM dd yyyy",
    label: "MMM dd yyyy (e.g. Jan 15 1990)",
  },
  {
    value: "yyyy-MM-dd",
    label: "yyyy-MM-dd (e.g. 2023-08-31)",
  },
  {
    value: "dd/MM/yyyy",
    label: "dd/MM/yyyy (e.g. 31/08/2023)",
  },
  {
    value: "MM-dd-yyyy",
    label: "MM-dd-yyyy (e.g. 08-31-2023)",
  },
  {
    value: "yyyy/MM/dd",
    label: "yyyy/MM/dd (e.g. 2023/08/31)",
  },
  {
    value: "dd-MMMM-yyyy",
    label: "dd-MMMM-yyyy (e.g. 31-August-2023)",
  },
  {
    value: "MMMM dd, yyyy",
    label: "MMMM dd, yyyy (e.g. August 31, 2023)",
  },
  {
    value: "dd/MM/yy",
    label: "dd/MM/yy (e.g. 31/08/23)",
  },
  {
    value: "MMMM dd, yy",
    label: "MMMM dd, yy (e.g. August 31, 23)",
  },
  {
    value: "yy/MM/dd",
    label: "yy/MM/dd (e.g. 23/08/31)",
  },
  {
    value: "MM-dd-yy",
    label: "MM-dd-yy (e.g. 01-15-90)",
  },
  {
    value: "M-dd-yy",
    label: "M-dd-yy (e.g. 1-15-90)",
  },
  {
    value: "dd-MM-yy",
    label: "dd-MM-yy (e.g. 15-01-90)",
  },
  {
    value: "dd-MM-yyyy",
    label: "dd-MM-yyyy (e.g. 15-01-1990)",
  },
  {
    value: "dd-M-yy",
    label: "dd-M-yy (e.g. 15-1-90)",
  },
  {
    value: "MM/dd/yy",
    label: "MM/dd/yy (e.g. 01/15/90)",
  },
  {
    value: "M/dd/yy",
    label: "M/dd/yy (e.g. 1/15/90)",
  },
  {
    value: "MM.dd.yy",
    label: "MM.dd.yy (e.g. 01.15.90)",
  },
  {
    value: "M.dd.yy",
    label: "M.dd.yy (e.g. 1.15.90)",
  },
  {
    value: "MMM-dd-yy",
    label: "MMM-dd-yy (e.g. Jan-15-90)",
  },
  {
    value: "MMMM-dd-yy",
    label: "MMMM-dd-yy (e.g. January-15-90)",
  },
  {
    value: "dd-MMM-yy",
    label: "dd-MMM-yy (e.g. 15-Jan-90)",
  },
  {
    value: "yyyy-M-dd",
    label: "yyyy-M-dd (e.g. 1990-1-15)",
  },
  {
    value: "M-dd-yyyy",
    label: "M-dd-yyyy (e.g. 1-15-1990)",
  },
  {
    value: "M/dd/yyyy",
    label: "M/dd/yyyy (e.g. 1/15/1990)",
  },
  {
    value: "dd/M/yyyy",
    label: "dd/M/yyyy (e.g. 15/1/1990)",
  },
  {
    value: "yyyy/M/dd",
    label: "yyyy/M/dd (e.g. 1990/1/15)",
  },
  {
    value: "dd.MM.yyyy",
    label: "dd.MM.yyyy (e.g. 15.01.1990)",
  },
  {
    value: "dd.M.yyyy",
    label: "dd.M.yyyy (e.g. 15.1.1990)",
  },
  {
    value: "yyyy.MM.dd",
    label: "yyyy.MM.dd (e.g. 1990.01.15)",
  },
  {
    value: "yyyy.M.dd",
    label: "yyyy.M.dd (e.g. 1990.1.15)",
  },
  {
    value: "MMM. dd, yyyy",
    label: "MMM. dd, yyyy (e.g. Jan. 15, 1990)",
  },
  {
    value: "dd MMM. yyyy",
    label: "dd MMM. yyyy (e.g. 15 Jan. 1990)",
  },
  {
    value: "MMM dd",
    label: "MMM dd (e.g. Jan 15)",
  },
  {
    value: "MMMM dd",
    label: "MMMM dd (e.g. January 15)",
  },
  {
    value: "MMM d",
    label: "MMM d (e.g. Jan 5)",
  },
  {
    value: "MMMM d",
    label: "MMMM d (e.g. January 5)",
  },
  {
    value: "dd. MMM. yyyy",
    label: "dd. MMM. yyyy (e.g. 15. Jan. 1990)",
  },
  {
    value: "EEE, MMM dd, yyyy",
    label: "EEE, MMM dd, yyyy (e.g. Mon, Jan 15, 1990)",
  },
  {
    value: "EEEE dd MMMM yyyy",
    label: "Day Day-of-Month Month Year (e.g. Tuesday 08 August 2023)",
  },
];

export const dateTimePatterns = [
  {
    value: "MM/dd/yyyy HH:mm",
    label: "MM/dd/yyyy HH:mm (e.g. 08/31/2023 12:30)",
  },
  {
    value: "MM/dd/yyyy HH:mm:ss",
    label: "MM/dd/yyyy HH:mm:ss (e.g. 08/31/2023 12:30:45)",
  },
  {
    value: "MM/dd/yyyy HH:mm:ss.SSS",
    label: "MM/dd/yyyy HH:mm:ss.SSS (e.g. 08/31/2023 12:30:45.500)",
  },
  {
    value: "MM/dd/yyyy h:mm a",
    label: "MM/dd/yyyy h:mm a (e.g. 08/31/2023 1:30 PM)",
  },
  {
    value: "MM/dd/yyyy h:mm:ss a",
    label: "MM/dd/yyyy h:mm:ss a (e.g. 08/31/2023 1:30:45 PM)",
  },
  {
    value: "MMM dd HH:mm:ss yyyy",
    label: "MMM dd HH:mm:ss yyyy (e.g. Jan 15 00:34:59 1990)",
  },
  {
    value: "MMM dd yyyy HH:mm:ss",
    label: "MMM dd yyyy HH:mm:ss (e.g. Jan 15 1990 00:34:59)",
  },
  {
    value: "dd MMM HH:mm:ss yyyy",
    label: "dd MMM HH:mm:ss yyyy (e.g. 15 Jan 00:34:59 1990)",
  },
  {
    value: "MM/dd/yyyy h:mm:ss.SSS a",
    label: "MM/dd/yyyy h:mm:ss.SSS a (e.g. 08/31/2023 1:30:45.500 PM)",
  },
  {
    value: "MMMM dd, yyyy HH:mm",
    label: "MMMM dd, yyyy HH:mm (e.g. August 31, 2023 12:30)",
  },
  {
    value: "MMMM dd, yyyy HH:mm:ss",
    label: "MMMM dd, yyyy HH:mm:ss (e.g. August 31, 2023 12:30:45)",
  },
  {
    value: "MMMM dd, yyyy h:mm a",
    label: "MMMM dd, yyyy h:mm a (e.g. August 31, 2023 1:30 PM)",
  },
  {
    value: "MMMM dd, yyyy h:mm:ss a",
    label: "MMMM dd, yyyy h:mm:ss a (e.g. August 31, 2023 1:30:45 PM)",
  },
  {
    value: "MMMM dd, yyyy h:mm:ss.SSS a",
    label: "MMMM dd, yyyy h:mm:ss.SSS a (e.g. August 31, 2023 1:30:45.500 PM)",
  },
  {
    value: "MM-dd-yy HH:mm",
    label: "MM-dd-yy HH:mm (e.g. 01-15-90 10:10)",
  },
  {
    value: "dd-MM-yyyy HH:mm",
    label: "dd-MM-yyyy HH:mm (e.g. 15-01-1990 10:10)",
  },
  {
    value: "dd/MM/yyyy HH:mm",
    label: "dd/MM/yyyy HH:mm (e.g. 15/01/1990 10:10)",
  },
  {
    value: "dd-MM-yyyy h:mm a",
    label: "dd-MM-yyyy h:mm a (e.g. 15-01-1990 10:10 AM)",
  },
  {
    value: "dd/MM/yyyy h:mm a",
    label: "dd/MM/yyyy h:mm a (e.g. 15/01/1990 10:10 AM)",
  },
  {
    value: "MM/dd/yy HH:mm",
    label: "MM/dd/yy HH:mm (e.g. 01/15/90 10:10)",
  },
  {
    value: "MM/dd/yy HH:mm:ss",
    label: "MM/dd/yy HH:mm:ss (e.g. 01/15/90 10:10:14)",
  },
  {
    value: "MM/dd/yy h:mm:ss a",
    label: "MM/dd/yy h:mm:ss a (e.g. 01/15/90 5:10:14 AM)",
  },
  {
    value: "yyyy/MM/dd HH:mm",
    label: "yyyy/MM/dd HH:mm (e.g. 1990/01/15 10:10)",
  },
  {
    value: "MM-dd-yyyy HH:mm",
    label: "MM-dd-yyyy HH:mm (e.g. 01-15-1990 10:10)",
  },
  {
    value: "yyyy-MM-dd HH:mm",
    label: "yyyy-MM-dd HH:mm (e.g. 1990-01-15 10:10)",
  },
  {
    value: "MMM dd HH:mm:ss",
    label: "MMM dd HH:mm:ss (e.g. Jan 15 10:10:25)",
  },
  {
    value: "MMMM dd HH:mm:ss",
    label: "MMMM dd HH:mm:ss (e.g. January 15 10:10:25)",
  },
  {
    value: "MMM d HH:mm:ss",
    label: "MMM d HH:mm:ss (e.g. Jan 5 10:10:25)",
  },
  {
    value: "MMMM d HH:mm:ss",
    label: "MMMM d HH:mm:ss (e.g. January 5 10:10:25)",
  },
  {
    value: "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
    label: "ISO 8601 (e.g. 1990-01-15T00:34:55.020+09:30)",
  },
  {
    value: "yyyy-MM-dd'T'HH:mm:ss.SSS",
    label: "ISO 8601 without timezone offset (e.g. 1990-01-15T00:34:55.020)",
  },
  {
    value: "yyyy-MM-dd'T'HH:mmZ",
    label: "yyyy-MM-dd'T'HH:mm+offset (e.g. 1990-01-15T10:10+09:30)",
  },
  {
    value: "yyyy-MM-dd'T'HH:mm",
    label: "yyyy-MM-dd'T'HH:mm (e.g. 1990-01-15T10:10)",
  },
];

export const timePatterns = [
  {
    value: "HH:mm",
    label: "HH:mm (e.g. 12:30)",
  },
  {
    value: "HH:mm:ss",
    label: "HH:mm:ss (e.g. 12:30:45)",
  },
  {
    value: "hh:mm a",
    label: "hh:mm a (e.g. 01:30 PM)",
  },
  {
    value: "hh:mm:ss a",
    label: "hh:mm:ss a (e.g. 01:30:45 PM)",
  },
];

export const allowedPatterns = {
  date: datePatterns,
  datetime: dateTimePatterns,
  time: timePatterns,
};
