import { Field, FieldTransformation } from "fuse-importer";
import {
  parseDate,
  parseDateTime,
  parseTimeFromDateTimeorTime,
} from "./dateTimeAutoformatUtils";

export type FieldTransformator = (
  fieldValue: any,
  transformation?: FieldTransformation,
  fieldOptions?: Field
) => { transformationWasNeeded: boolean; original: any; transformed: any };

export type FieldTransformations = Partial<{
  [key: string]: FieldTransformator;
}>;

export const isTransformationNeeded = (
  fieldValue: any,
  transformedValue: any
) => ({
  transformationWasNeeded: !!transformedValue
    ? fieldValue !== transformedValue
    : false,
  original: fieldValue,
  transformed:
    fieldValue === transformedValue || transformedValue === undefined
      ? null
      : transformedValue,
});

const isNotANumber = (fieldValue: number) =>
  fieldValue === null || fieldValue === undefined || isNaN(fieldValue);

const isNotAString = (fieldValue: string) =>
  !fieldValue || typeof fieldValue !== "string" || fieldValue.trim() === "";

const stringFieldTransformations: FieldTransformations = {
  sentence_case(fieldValue) {
    if (isNotAString(fieldValue))
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const transformedValue =
      fieldValue?.toLowerCase()?.charAt(0)?.toUpperCase() +
      fieldValue?.slice(1).toLowerCase();

    return isTransformationNeeded(fieldValue, transformedValue);
  },
  capitalize(fieldValue) {
    if (isNotAString(fieldValue)) {
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };
    }

    const transformedValue = fieldValue
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return isTransformationNeeded(fieldValue, transformedValue);
  },
  uppercase(fieldValue) {
    if (isNotAString(fieldValue))
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const transformedValue = fieldValue?.toUpperCase();

    return isTransformationNeeded(fieldValue, transformedValue);
  },
  lowercase(fieldValue) {
    if (isNotAString(fieldValue))
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const transformedValue = fieldValue?.toLowerCase();

    return isTransformationNeeded(fieldValue, transformedValue);
  },
  trim_whitespace(fieldValue) {
    if (typeof fieldValue !== "string")
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const transformedValue = fieldValue?.trim();

    return isTransformationNeeded(fieldValue, transformedValue);
  },
};

export const datesDateTimesAndTimesTransformations: FieldTransformations = {
  autoformat(fieldValue: string, _, fieldOptions: Field) {
    if (fieldValue === null || fieldValue === undefined)
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const { type, pattern } = fieldOptions;
    const parseFunctions = {
      date: parseDate,
      datetime: parseDateTime,
      time: parseTimeFromDateTimeorTime,
    };

    const parseFn = parseFunctions[type];
    let dateValue = null;
    try {
      dateValue = parseFn(fieldValue, pattern);
    } catch (e) {
      console.log("Unable to parse date", e);
    }

    const transformedValue = dateValue?.toFormat(pattern);
    return isTransformationNeeded(fieldValue, transformedValue);
  },
};

const urlTransformations: FieldTransformations = {
  prefix(fieldValue, transformation) {
    const prefixValue = transformation?.options?.prefix;
    const fieldValueHasPrefix = fieldValue?.startsWith(prefixValue);

    if (
      isNotAString(prefixValue) ||
      isNotAString(fieldValue) ||
      fieldValueHasPrefix
    )
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };

    const transformedValue = `${prefixValue}${fieldValue}`;

    return isTransformationNeeded(fieldValue, transformedValue);
  },
};

const floatTransformations: FieldTransformations = {
  number_of_decimals(fieldValue, transformation) {
    const numberOfDecimalsValue = Number(
      transformation?.options?.number_of_decimals
    );

    if (isNotANumber(numberOfDecimalsValue) || isNotANumber(fieldValue)) {
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };
    }

    const transformedValue = parseFloat(
      Number(fieldValue).toFixed(numberOfDecimalsValue)
    );

    return isTransformationNeeded(fieldValue, transformedValue);
  },
};

const integerTransformations: FieldTransformations = {
  round(fieldValue) {
    if (fieldValue === "" || isNotANumber(fieldValue)) {
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };
    }

    const typeofFieldValue = typeof fieldValue;

    const roundedValue = Math.round(fieldValue);
    const valueWithCorrectType =
      typeofFieldValue === "string" ? `${roundedValue}` : roundedValue;

    return isTransformationNeeded(fieldValue, valueWithCorrectType);
  },
  floor(fieldValue) {
    if (fieldValue === "" || isNotANumber(fieldValue)) {
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };
    }

    const typeofFieldValue = typeof fieldValue;

    const flooredValue = Math.floor(fieldValue);
    const valueWithCorrectType =
      typeofFieldValue === "string" ? `${flooredValue}` : flooredValue;

    return isTransformationNeeded(fieldValue, valueWithCorrectType);
  },
  ceiling(fieldValue) {
    if (fieldValue === "" || isNotANumber(fieldValue)) {
      return {
        transformationWasNeeded: false,
        original: fieldValue,
        transformed: null,
      };
    }

    const typeofFieldValue = typeof fieldValue;

    const ceilingValue = Math.ceil(fieldValue);
    const valueWithCorrectType =
      typeofFieldValue === "string" ? `${ceilingValue}` : ceilingValue;

    return isTransformationNeeded(fieldValue, valueWithCorrectType);
  },
};

export const fieldTransformations = {
  ...stringFieldTransformations,
  ...datesDateTimesAndTimesTransformations,
  ...urlTransformations,
  ...floatTransformations,
  ...integerTransformations,
};
