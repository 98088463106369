import FuseImporter, { getFuseClientSideToken } from "fuse-importer";
import { useState } from "react";

// used in cypress tests for verifying that integration for Importer component with iframe is working
const ImporterIframeTest = () => {
  const [apiToken, setApiToken] = useState("");
  const [templateSlug, setTemplateSlug] = useState("");

  const showImporter = () => {
    const importer = new FuseImporter({
      options: {
        env: "local",
      },
    });

    importer.getSessionToken = getFuseClientSideToken({
      importer_slug: templateSlug,
      api_key: apiToken,
      metadata: {
        test: "test",
      },
    });

    importer.formatRecord = (record) => {
      if (window["formatRecord"]) return window["formatRecord"](record);
      const newRecord = { ...record };

      return newRecord;
    };

    (() => {
      importer.addCustomAction([
        {
          actionType: "record",
          name: "Change first column to FirstTestE2E",
          handler: (record) => {
            const columns = Object.keys(record);
            const firstColumn = columns.filter(
              (column) => column !== "_meta"
            )[0];

            record[firstColumn] = "FirstTestE2E";

            return record;
          },
        },
        {
          actionType: "record",
          name: "Change first column to SecondTestE2E",
          handler: (record) => {
            const columns = Object.keys(record);
            const secondColumn = columns.filter(
              (column) => column !== "_meta"
            )[0];

            record[secondColumn] = "SecondTestE2E";

            return record;
          },
        },
        {
          actionType: "record",
          name: "Throw error on second column",
          handler: (record) => {
            const columns = Object.keys(record);
            const secondColumn = columns.filter(
              (column) => column !== "_meta"
            )[1];

            record[secondColumn] = "Invalid value";

            return record;
          },
        },
      ]);
    })();

    importer.onValidateRecord = async (record) => {
      if (window["onValidateRecord"]) return window["onValidateRecord"](record);

      return { errors: {}, warnings: {} };
    };

    importer.onSubmit = async (records) => {
      if (window["onSubmit"]) {
        const result = window["onSubmit"](records);

        return result;
      }

      return {
        message: "Custom Test Success Message",
        errors: {},
      };
    };

    importer.show();
  };

  return (
    <>
      <div style={{ fontSize: "14px", margin: "100px" }}>
        <div>
          <div style={{ width: "100px" }}>Api Key:</div>
          <input
            id="organization-api-key"
            style={{ width: "400px" }}
            value={apiToken}
            onChange={({ target: { value } }) => setApiToken(value)}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ width: "100px" }}>Importer Slug:</div>
          <input
            style={{ width: "400px" }}
            value={templateSlug}
            id="template-slug"
            onChange={({ target: { value } }) => setTemplateSlug(value)}
          />
        </div>
        <button onClick={showImporter}>Show Importer</button>
      </div>
    </>
  );
};

export default ImporterIframeTest;
