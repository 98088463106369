import { useAppModalContext } from "components/AppModalContextProvider";
import { Header } from "components/common/Header";
import { SectionContainer } from "components/common/SectionContainer";
import { BlankState } from "components/common/Table/BlankState";
import { useUserContext } from "components/UserContextProvider";
import {
  addToast,
  Button,
  colors,
  Div,
  Table,
  TableDataProvider,
  TooltipProvider,
  useTableContext,
} from "fuse-shared-ui";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import styled from "styled-components";
import { TEMPLATE_COLUMNS_ROUTE } from "..";
import CreateTemplateFormModal from "./CreateTemplateFormModal";
import { tableColumns } from "./tableColumns";

type Template = {
  slug: string;
};

const HeaderSection = ({ createTemplateButton }) => {
  const { data } = useTableContext();

  const rightContent = (
    <Div alignCenter>{data && data.length > 0 && createTemplateButton}</Div>
  );

  return (
    <Header
      testID="importerTitle"
      title="Importers"
      rightContent={rightContent}
    />
  );
};

const Templates = ({ testId }: { testId?: string }) => {
  const history = useHistory();
  const { showModal } = useAppModalContext();

  const goToColumns = (template: string | Template): void => {
    if (typeof template !== "string") {
      template = template?.slug;
    }

    history.push(TEMPLATE_COLUMNS_ROUTE.replace(":slug", template));
  };

  const showTemplateFormModal = useCallback(() => {
    showModal(({ hideModal, isOpen }) => (
      <CreateTemplateFormModal
        isOpen={isOpen}
        hideModal={hideModal}
        onSubmit={goToColumns}
      />
    ));
  }, []);

  const createImporterButtonUI = (
    <Button
      data-cy="createImporterButton"
      onClick={showTemplateFormModal}
      isSmall
    >
      Create an Importer
    </Button>
  );

  const blankStateUI = (
    <Div dflex mt={120} w100 data-cy="createImporterText">
      <BlankState
        title="Create an Importer"
        description="Importers define the structure you want incoming data to take on. Create an importer and start importing data."
        button={createImporterButtonUI}
      />
    </Div>
  );

  return (
    <TableDataProvider dataUrl={"/api/v1/templates"}>
      <Helmet>
        <title>Flatirons Fuse - Importers</title>
      </Helmet>

      <SectionContainer data-testid={testId} c={colors.gray800} flexColumn h100>
        <HeaderSection createTemplateButton={createImporterButtonUI} />
        <Div mt={24} h100>
          <Table
            columns={tableColumns}
            blankState={blankStateUI}
            onRowClick={goToColumns}
            disableSorting
          />
        </Div>
      </SectionContainer>
    </TableDataProvider>
  );
};

const copyIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="11.5"
      y="10"
      width="12"
      height="13"
      rx="3"
      stroke={colors.black}
      strokeWidth="2"
    />
    <path
      d="M13.5 4.71429V4C13.5 2.34315 12.1569 1 10.5 1H4.5C2.84315 1 1.5 2.34315 1.5 4V11C1.5 12.6569 2.84315 14 4.5 14H6.5"
      stroke={colors.black}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Templates;
