import {
  CustomAction,
  FormatRecord,
  ImporterOptions,
  OnSubmit,
  OnValidateRecord,
  TemplateHeader,
} from "fuse-importer";
import jwtDecode from "jwt-decode";
import { ImporterContextProvider } from "./contexts/ImporterContextProvider";
import { ImporterWithContext } from "./ImporterWithContext";
import { useEffect, useMemo, useState } from "react";
import { FuseApi } from "./common/FuseApi";
import { AppLoadingWithTheme } from "./common/AppLoading";

export const ACCOUNT_ROUTES = {
  TEMPLATES: "/importers",
};
export const ACCOUNT_VIEW_PATH = "/account";
const p = (path) => `${ACCOUNT_VIEW_PATH}${path}`;

export const TEMPLATES_ROUTE = p(ACCOUNT_ROUTES.TEMPLATES);

export type ImporterProps = {
  templateSlug: string;
  sessionToken?: string;
  apiToken?: string;
  options: ImporterOptions;
  previewMode?: boolean;
  formatRecord: FormatRecord;
  onValidateRecord?: OnValidateRecord;
  dynamicColumns: TemplateHeader[];
  customActions: CustomAction[];
  onSubmit?: OnSubmit;
  onClose: () => void;
};

const Importer = ({
  templateSlug,
  apiToken,
  sessionToken,
  options,
  formatRecord,
  onValidateRecord,
  dynamicColumns,
  customActions,
  onSubmit,
  onClose,
}: ImporterProps) => {
  const [fuseApi, setFuseApi] = useState<FuseApi | null>(null);

  const _templateSlug = useMemo(
    () =>
      templateSlug ||
      (() => {
        if (sessionToken) {
          try {
            const decodedToken: any = jwtDecode(sessionToken);
            return decodedToken.importer_slug;
          } catch (error) {
            console.error("Failed to decode JWT token:", error);
          }
        }
        return null;
      })(),
    [sessionToken]
  );

  useEffect(() => {
    const initializeFuseApi = async () => {
      const apiInstance = new FuseApi(options.env, apiToken, sessionToken);
      await apiInstance.initialize(_templateSlug);
      setFuseApi(apiInstance);
    };
    initializeFuseApi();
  }, [options.env, apiToken, sessionToken]);

  if (!fuseApi) {
    if (process.env.REACT_APP_CYPRESS === "true") return <div>loading</div>;
    return <AppLoadingWithTheme />;
  }

  return (
    <ImporterContextProvider
      initialRecords={options.records}
      options={options}
      fuseApi={fuseApi}
      templateSlug={_templateSlug}
      apiToken={apiToken}
      formatRecord={formatRecord}
      onValidateRecord={onValidateRecord}
      onClose={onClose}
      onSubmit={onSubmit}
      dynamicColumns={dynamicColumns}
      customActions={customActions}
    >
      <ImporterWithContext />
    </ImporterContextProvider>
  );
};

export * from "./common/Spreadsheet";
export * from "./common/utils";
export * from "./Review/ReviewContextProvider";
export { Importer };
