import {
  ColumnRequiredProps,
  CustomAction,
  CustomActionTypes,
  FieldTypesAsUnion,
  FormatRecord,
  GetSessionToken,
  ImporterOptions,
  OnSubmit,
  OnValidateRecord,
  Record,
} from "./types";
export const fuseImporterId = "fuse-importer-root";
export const FUSE_INIT = "fuse_init";
export const IFRAME_READY = "iframe_ready";
const defaultImporterHost = "https://embed.flatirons.com";

const isBrowser = typeof window !== "undefined";
const originalWarn = console.warn.bind(console.warn);
console.warn = (msg: string) => {
  if (!msg) return;
  if (msg.includes("Over 200 classes were generated for component")) return;
  originalWarn(msg);
};

type FuseClientSideTokenParams = {
  importer_slug: string;
  api_key: string;
  metadata?: any;
};

type GetSessionTokenFn = () => Promise<string>;

type GetFuseClientSideToken = (
  params: FuseClientSideTokenParams
) => GetSessionTokenFn;

export const getFuseClientSideToken: GetFuseClientSideToken = ({
  importer_slug,
  api_key,
  metadata = {},
}) => {
  const baseUrl =
    process.env.REACT_APP_CYPRESS === "true"
      ? "http://localhost:3000"
      : process.env.REACT_APP_API_BASE_URL || "https://fuse.flatirons.com";

  return async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/importer/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          api_token: api_key,
          importer_slug,
          metadata,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Failed to fetch session token:", error);
      throw error;
    }
  };
};

if (typeof process === "undefined") {
  const process: any = { env: {} };
  window.process = process;
}

class FuseImporter {
  apiToken: string;
  sessionToken: string;
  templateSlug: string;
  options: ImporterOptions;
  records: Record[];

  public constructor();
  public constructor(
    apiToken: string,
    templateSlug: string,
    options?: ImporterOptions
  );
  public constructor(params?: {
    apiToken?: string;
    templateSlug?: string;
    options?: ImporterOptions;
  });
  public constructor(
    arg1?:
      | string
      | { apiToken?: string; templateSlug?: string; options?: ImporterOptions },
    arg2?: string,
    arg3?: ImporterOptions
  ) {
    const defaultOptions: ImporterOptions = {
      env: "production",
      zIndex: 1000,
      batchValidationDelayMs: 500,
    };

    if (typeof arg1 === "string") {
      this.apiToken = arg1;
      this.templateSlug = arg2;
      this.options = { ...defaultOptions, ...arg3 };
    } else if (typeof arg1 === "object" && arg1 !== null) {
      this.apiToken = arg1.apiToken;
      this.templateSlug = arg1.templateSlug;
      this.options = { ...defaultOptions, ...arg1.options };
    } else {
      // No arguments provided
      this.apiToken = undefined;
      this.templateSlug = undefined;
      this.options = defaultOptions;
    }

    if (!isBrowser) return;

    this.options.host = defaultImporterHost;
    if (process.env.REACT_APP_IMPORTER_HOST) {
      this.options.host = process.env.REACT_APP_IMPORTER_HOST;
    }
    if (process.env.REACT_APP_CYPRESS) {
      this.options.host = "http://localhost:3000/cypress-test/importer-embed";
    }
    this.sessionToken = undefined;
  }

  private dynamicColumns: ColumnRequiredProps<any>[] = [];

  private customActions: CustomAction[] = [];

  private cleanupIframe: (() => void) | null = null;

  public formatRecord: FormatRecord;

  public onValidateRecord: OnValidateRecord;

  public onSubmit: OnSubmit;

  public getSessionToken: GetSessionToken;

  public onClose: () => void;

  private windowScrollPosition = {
    x: 0,
    y: 0,
  };

  close() {
    if (!isBrowser) return;
    this.onClose?.();

    if (this.cleanupIframe) {
      this.cleanupIframe();
      this.cleanupIframe = null;
    }

    const iframe = document.getElementById(fuseImporterId);
    document.body.removeChild(iframe);

    // allow the scroll when the importer is closed
    document.body.style.overflow = "auto";
    window.scrollTo(this.windowScrollPosition.x, this.windowScrollPosition.y);
  }

  public async mountFuseWebappIframe() {
    if (!isBrowser) return;
    const iframe = document.createElement("iframe");
    iframe.setAttribute("id", fuseImporterId);
    iframe.style.cssText =
      "border: none; width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 1000;";
    iframe.setAttribute("allow", "clipboard-write");
    iframe.src = `${this.options.host}`;
    // Show spinner
    const spinner = document.createElement("div");
    spinner.innerHTML = spinnerHTML;
    document.body.appendChild(spinner);

    if (this.getSessionToken) {
      try {
        this.sessionToken = await this.getSessionToken();
      } catch (error) {
        console.error("Failed to get session token", error);
      }
    }

    iframe.onload = () => {
      spinner.remove();
    };

    const configureIframeActions = () => {
      const iframeMesageHandler = async (message) => {
        const { type, id, payload } = message.data;
        const actions = {
          onSubmit: this.onSubmit,
          onValidateRecord: this.onValidateRecord,
          formatRecord: this.formatRecord,
          customAction: async (index, record) => {
            return await this.customActions[index].handler(record);
          },
          onClose: () => {
            window.removeEventListener("message", iframeMesageHandler, false);
            this.close();
          },
        };
        if (actions[type]) {
          const response = await actions[type](...payload);
          iframe?.contentWindow?.postMessage(
            { type, id, payload: response },
            "*"
          );
        }
      };
      window.addEventListener("message", iframeMesageHandler, false);
    };

    const configureIframeInit = () => {
      const iframeMesageHandler = async (message) => {
        const { type } = message.data;
        if (type === IFRAME_READY) {
          iframe?.contentWindow?.postMessage(
            {
              type: FUSE_INIT,
              payload: {
                sessionToken: this.sessionToken,
                apiToken: this.apiToken,
                templateSlug: this.templateSlug,
                options: this.options,
                dynamicColumns: this.dynamicColumns,
                hasFormatRecord: this.formatRecord !== undefined,
                hasOnValidateRecord: this.onValidateRecord !== undefined,
                hasOnSubmit: this.onSubmit !== undefined,
                customActions: this.customActions?.map((ca) => ({
                  name: ca.name,
                  actionType: ca.actionType,
                })),
              },
            },
            "*"
          );
        }
      };
      window.addEventListener("message", iframeMesageHandler, false);
    };

    configureIframeActions();
    configureIframeInit();

    document.body.appendChild(iframe);
  }

  public async show() {
    if (!isBrowser) return;
    // saves the position of the page before the importer was opened
    this.windowScrollPosition = {
      x: window.scrollX,
      y: window.scrollY,
    };

    // block the scroll when opening the importer
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    await this.mountFuseWebappIframe();
  }

  public addColumn<T extends FieldTypesAsUnion>(
    columnData: ColumnRequiredProps<T>
  ) {
    this.dynamicColumns.push(columnData);
  }

  public addCustomAction<T extends CustomActionTypes>(
    actions: CustomAction<T>[] | CustomAction<T>
  ) {
    if (!Array.isArray(actions)) actions = [actions];
    this.customActions.push(...actions);
  }

  static getFuseClientSideToken: GetFuseClientSideToken = getFuseClientSideToken;
}

export * from "./types";
export default FuseImporter;

const spinnerHTML = `
<div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.6); z-index: 1050; display: flex; justify-content: center; align-items: center;">
  <div style="width: 70px; display: flex; justify-content: space-between;">
    <div style=" margin: 0 5px 0 5px; width: 12px; height: 12px; background-color: #CAC4BF; border-radius: 50%; animation: bounce 1.4s infinite ease-in-out both; animation-delay: -0.32s;"></div>
    <div style=" margin: 0 5px 0 5px; width: 12px; height: 12px; background-color: #CAC4BF; border-radius: 50%; animation: bounce 1.4s infinite ease-in-out both; animation-delay: -0.16s;"></div>
    <div style="margin: 0 5px 0 5px; width: 12px; height: 12px; background-color: #CAC4BF; border-radius: 50%; animation: bounce 1.4s infinite ease-in-out both;"></div>
  </div>
</div>
<style>
  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1.0); }
  }
</style>
`;
