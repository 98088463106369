/* eslint-disable import/no-anonymous-default-export */
export default {
  preview: {
    title: "Sie sind dabei, Ihren Importer zu Vorschauen.",
    description:
      "Laden Sie unsere Beispiel-CSV herunter, um Ihren Importer auszuprobieren. Wir haben es zufällig generiert, damit Sie sehen können, wie Fuse die Daten transformiert.",
    download: "CSV herunterladen",
    note: {
      title: "Hinweis:",
      description:
        "Dieser Bildschirm wird nicht mehr existieren, sobald Sie den Importer auf Ihrer Website installiert haben.",
    },
  },
  upload: {
    headers_preview: {
      title: "Wählen Sie eine Kopfzeile aus",
      description:
        "Die Datei muss Spaltenüberschriften enthalten, um fortzufahren.",
      cancel: "Nein, Datei erneut hochladen",
      continue: "Ja, Weiter",
    },
    error: {
      title: "Irgendetwas ist falsch",
    },
    header: {
      title:
        "Stellen Sie sicher, dass Ihr Dokument Daten enthält, die ähnlich sind wie diese:",
      required_headers: "Erforderliche Überschriften",
      optional_headers: "Optionale Überschriften",
    },
    title: "Laden Sie eine Datei hoch, um Ihre Daten zu importieren",
    description: `
    Laden Sie eine beliebige .csv, .xls, .xlsx-Tabellenkalkulationsdatei
    mit einem beliebigen Satz von Spalten hoch, solange es einen Datensatz pro Zeile enthält.
  `,
    loading: {
      title: "Hochladen...",
    },
    dropzone: {
      title: "Ziehen & lassen Sie Ihre Datei hier",
      description: "oder",
      button: "Dateien durchsuchen",
      enter_data_manually: "Daten manuell eingeben",
    },
  },
  match: {
    title: "Passen Sie Ihre Spalten unseren an",
    description:
      "Wir konnten {{numberOfMatchedHeaders}} von {{totalNumberOfHeaders}} Spalten korrekt zuordnen!",
    enum_description: `
    Unser System akzeptiert nur bestimmte Werte für einige Spalten. Unten können
    Sie die Werte aus Ihrer Tabelle den Werten zuordnen, die wir akzeptieren.
  `,
    columns: {
      uploaded_columns: "Hochgeladene Spalten",
      supported_columns: "Unterstützte Spalten",
      uploaded_values: "Hochgeladene Werte",
      supported_values: "Unterstützte Werte",
      content_example: "Inhaltsbeispiel",
      merge_column_tooltip: "Mehrere Spalten in {{column}} zusammenführen",
      status: "Status",
      placeholder: "Wählen Sie eine Option aus...",
      values_for_the_column: "Werte für die {{column}}-Spalte",
      matched: "Zugeordnet",
      unmatched: "Nicht zugeordnet",
      combine_data: {
        title: "Daten für {{column}} kombinieren",
        description:
          "Wählen Sie die Spalten aus, die Sie kombinieren möchten, und wählen Sie das Trennzeichen aus.",
        select_columns:
          "Wählen Sie die Spalten aus, die Sie kombinieren möchten",
        choose_delimeter: "Wählen Sie ein Trennzeichen aus",
        data_preview: "Daten Vorschau",
        success_toast: "Spalten erfolgreich kombiniert",
        cancel_button: "Abbrechen",
        combine_button: "Kombinieren",
      },
    },
  },
  review: {
    title: "Überprüfen und Senden",
    manual_importer_title: "Daten Eingeben und Senden",
    export: "Exportieren",
    description:
      "Einige Zellen im Dokument können Fehler im Format oder fehlende Informationen aufweisen. Bitte überprüfen Sie diese, um fortzufahren.",
    table_actions: {
      filters_by_rows: {
        all_rows: "Alle Zeilen",
        invalid_rows: "Ungültige Zeilen",
        duplicates: "Gültige",
      },
      search_rows: {
        tooltip: "Etwas suchen",
        placeholder: "Etwas suchen...",
      },
      filter_by_errors: {
        search_or_select: "Suchen oder auswählen",
        filter_by_errors: "Nach Fehlern filtern",
      },
      delete_rows: {
        confirmation_modal: "Möchtest du wirklich {{length}} Zeile löschen",
        modal_title: "Ausgewählte Zeile löschen",
        yes_delete: "Ja, löschen",
        select_rows: "Wählen Sie Zeilen zum Löschen aus",
        delete_row: "Zeile löschen",
        deleted_message: "{{length}} Zeilen gelöscht",
      },
      export_your_data: {
        tooltip: "Exportiere deine Daten",
      },
      transformed_records: {
        auto_corrections_done: "zellen automatisch korrigiert",
        example: "Beispiel",
        auto_corrections_amount:
          "Wir haben {{amount}} Probleme automatisch korrigiert.",
      },
      custom_actions: {
        label: "Weitere Aktionen",
        general_error_toast:
          "Die Aktion konnte nicht ausgeführt werden. Bitte versuchen Sie es erneut.",
        error_toast:
          'Beim Ausführen der Aktion "{{actionName}}" auf den ausgewählten Zeilen ist ein Fehler aufgetreten. Bitte überprüfen Sie die Code-Implementierung und versuchen Sie es erneut.',
        success_toast:
          'Die Aktion "{{actionName}}" wurde erfolgreich auf den ausgewählten Zeilen ausgeführt.',
      },
    },
    persistence: {
      loader_modal: {
        title: "Import wird übermittelt...",
        subtitle:
          "Bitte warten. Das Fenster wird geschlossen, wenn der Vorgang abgeschlossen ist",
        leave_warning:
          "Sind Sie sicher, dass Sie gehen möchten? Ihre Änderungen werden möglicherweise nicht gespeichert.",
      },
      response_modal: {
        success_header: "Daten erfolgreich eingereicht!",
        success_default:
          "Ihre Daten wurden erfolgreich übermittelt. Klicken Sie unten, um fortzufahren.",
        error_default:
          "Einige Datensätze hatten Fehler beim Importieren. Bitte überprüfen Sie die Datensätze mit Fehlern und reichen Sie sie erneut ein.",
        close_modal_button: "OK, schließen",
      },
      retry_modal: {
        title: "Beim Einreichen des Imports ist etwas schief gelaufen",
        description:
          "Keine Sorge, versuchen Sie es erneut, den Import einzureichen.",
        cancel: "Zurückgehen",
        try_again: "Erneut versuchen",
      },
    },
  },
  general: {
    autocomplete: {
      placeholder: "Wählen Sie eine Option aus...",
    },
    multi_select: {
      max_limit_error: "Sie können nicht mehr als 10 Optionen auswählen",
    },
    steps: {
      upload: "Hochladen",
      match: "Zuordnen",
      review: "Überprüfen",
    },
    stepper: {
      go_back: "Zurück gehen",
      continue: "Weiter",
      submit: "Einreichen",
    },
    unmatched_headers_modal: {
      title: "Nicht zugeordnete Spaltenüberschriften",
      description_singular:
        "Es gibt {{count}} nicht zugeordnete Spaltenüberschriften. Sie müssen diese manuell ausfüllen, wenn Sie fortfahren.",
      description_plural:
        "Es gibt {{count}} nicht zugeordnete Spaltenüberschriften. Sie müssen diese manuell ausfüllen, wenn Sie fortfahren.",
      assign_headers: "Überschriften zuordnen",
      skip: "Überspringen",
    },
    submit_modal: {
      title: "Bereit zum Einreichen?",
      description:
        "Wir haben in Ihrer Datei nichts falsches gefunden. Sie können loslegen!",
      submit: "Ja, einreichen",
      cancel: "Zurück gehen",
    },
    confirm_close_modal: {
      title: "Sind Sie sicher?",
      description:
        "Das Schließen des Importers wird alle Änderungen verlieren.",
      close: "Schließen",
      cancel: "Abbrechen",
    },
    preview_mode_banner: {
      title: "Sie sind derzeit im Vorschau-Modus.",
    },
  },
  warnings_banner: {
    trial_mode: {
      info_text:
        "Dieses Konto befindet sich im Testmodus. Importe sind auf 100 Datensätze beschränkt.",
      button_text: "Plan auswählen",
    },
    invalid_submit_fn: {
      info_text:
        "Senden ist deaktiviert. Schalten Sie den Inkognito-Modus aus oder fügen Sie einen onSubmit-Hook hinzu, um das Senden zu aktivieren.",
      button_text: "Wie geht das?",
    },
  },
};
