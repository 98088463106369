import styled, { css } from "styled-components";
import { useImporterContext } from "../../../Importer/contexts/ImporterContextProvider";
import { Div } from "../../../styled/utils";
import { theme } from "../../../styled/theme";
import {
  DragAndDropSvg,
  UploadIndicatorSvg,
} from "../../../Importer/Uploader/DragAndDropImporter/backgrounds";
import { useTranslation } from "react-i18next";
import {
  Button,
  DropzoneProps as ImporterDropzoneProps,
  addToast,
  useDragAndDrop,
} from "../../../common";

type DragAndDropCardProps = {
  isDragActive: boolean;
};
const DragAndDropCard = styled(Div)<DragAndDropCardProps>`
  border-radius: 8px;
  margin-right: ${(props) => (props.isDragActive ? "-10px" : "revert")};
  width: 100%;
  height: 100%;
  display: flex;
  background: ${theme.colors.white};
  border: 1px dashed ${theme.colors.red50};

  @media (max-width: 1050px) {
    padding: 24px;
    width: auto;
  }

  ${(props) =>
    props.isDragActive &&
    css`
      background: ${(p) => p.theme.colors.blue100};
      border: 1px solid ${(p) => p.theme.colors.blue500};
      justify-content: center;
    `}
`;

const DropzoneUploadingContainer = styled(Div)(
  ({ theme: { colors } }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${colors.blue100};
    border: 1px solid ${colors.blue500};
  `
);

const dropzoneUploadingUI = (
  <DropzoneUploadingContainer centered>
    <Div>
      <Div centered mb={12}>
        {UploadIndicatorSvg}
      </Div>
      <Div h5> Drop your file here to upload!</Div>
    </Div>
  </DropzoneUploadingContainer>
);

interface DragAndDropComponentProps {
  openUploadFileDialog: () => void;
}

const DragAndDropComponent = ({
  openUploadFileDialog,
}: DragAndDropComponentProps) => {
  const { t } = useTranslation("upload");

  const { manualDataImportSelected } = useImporterContext();

  return (
    <Div centered flexColumn>
      <Div mb={12}>{DragAndDropSvg}</Div>
      <Div h5 data-cy="drag-drop-file">
        {t("dropzone.title")}
      </Div>
      <Button
        data-cy="browse-file"
        mt={20}
        variant="secondary"
        w={"fit-content"}
        p={12}
        isSmall
        onClick={openUploadFileDialog}
      >
        {t("dropzone.button")}
      </Button>

      <Div body1 textAlignCenter m={4} mt={24}>
        {t("dropzone.description")}
      </Div>

      <Div>
        <Button
          data-cy="enter-manually"
          variant="link"
          w={"fit-content"}
          p={12}
          isSmall
          onClick={manualDataImportSelected}
        >
          {t("dropzone.enter_data_manually")}
        </Button>
      </Div>
    </Div>
  );
};

const removeEmptyRows = (data: any[]) => {
  return data.filter((row) =>
    row.some(
      (cell) => cell !== null && cell !== undefined && cell.trim() !== ""
    )
  );
};

const ImporterDropzone = ({
  onComplete,
  setIsLoading,
  saveFileName,
}: ImporterDropzoneProps) => {
  const validHeadersCount = (headersLength: number) => {
    const headersCountLimit = 70;
    if (headersLength > headersCountLimit) {
      addToast(
        `File cannot contain more than ${headersCountLimit} columns`,
        "error"
      );
      return false;
    } else {
      return true;
    }
  };

  const { getInputProps, getRootProps, isDragActive, open } = useDragAndDrop({
    onComplete: (data) => onComplete(removeEmptyRows(data)),
    saveFileName,
    setIsLoading,
    validHeadersCount,
  });

  return (
    <DragAndDropCard
      isDragActive={isDragActive}
      centered
      pRelative
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && dropzoneUploadingUI}
      <DragAndDropComponent openUploadFileDialog={open} />
    </DragAndDropCard>
  );
};

export { ImporterDropzone };
