import { Div, Spinner } from "fuse-shared-ui";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { PaymentMethodsIds } from "__data__";
import * as Styles from "./PaymentMethodCard.styles";

export type Card = {
  brand: string;
  last4: string;
};

export type Props = {
  isLoading: boolean;
  cardHolder: string;
  card: Card;
  nextBillingDate: number;
  onChangePaymentMethod?: () => void;
  testIds?: PaymentMethodsIds;
};

const DATE_FORMAT = "MMMM dd, yyyy";
export const formatDate = (date: number) =>
  DateTime.fromJSDate(new Date(date)).toFormat(DATE_FORMAT);

export const PaymentMethodCard = ({
  isLoading,
  cardHolder,
  card,
  nextBillingDate,
  onChangePaymentMethod,
  testIds,
}: Props) => {
  const nextBillingDateFormatted = useMemo(() => formatDate(nextBillingDate), [
    nextBillingDate,
  ]);

  const isBillingDateValid = nextBillingDateFormatted !== "Invalid DateTime";

  const paymentMethodUI = (
    <>
      <Styles.Item data-cy="payment-detail">
        <Styles.Label>Payment Method</Styles.Label>
        <Styles.CardHolder mt={12}>{cardHolder}</Styles.CardHolder>
        {isBillingDateValid && (
          <Styles.NextBillingDate mt={8} data-testid={testIds?.billingDateId}>
            Your next billing date is {nextBillingDateFormatted}
          </Styles.NextBillingDate>
        )}
      </Styles.Item>
      <Styles.Item>
        <Div dflex h100 alignCenter>
          {Styles.cardBrandsIcons[card.brand]}
          <Styles.CardDigits ml={10}>&nbsp;{"****"}&nbsp;</Styles.CardDigits>
          <Styles.CardDigits>&nbsp;{"****"}&nbsp;</Styles.CardDigits>
          <Styles.CardDigits>&nbsp;{"****"}&nbsp;</Styles.CardDigits>
          <Styles.CardDigits data-testid={testIds?.cardDigitsId}>
            &nbsp;{card.last4}&nbsp;
          </Styles.CardDigits>
          <Styles.Divider>|</Styles.Divider>
          <Styles.PaymentButton
            data-cy="addedCardLink"
            link
            onClick={onChangePaymentMethod}
          >
            Change
          </Styles.PaymentButton>
        </Div>
      </Styles.Item>
    </>
  );
  return (
    <Styles.Wrapper>{isLoading ? <Spinner /> : paymentMethodUI}</Styles.Wrapper>
  );
};

export default PaymentMethodCard;
