import { DateTime } from "luxon";

const MONTH_NAMES = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const MONTH_ABBREVIATIONS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
const DAY_NAMES = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const DAY_ABBREVIATIONS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const tryParseCommonDateFormats = (input: string): DateTime | null => {
  const trimmedInput = input.trim();
  const len = trimmedInput.length;
  if (len < 6 || len > 10) return null;

  const parts = input.split(/[-/.]/);
  if (parts.length !== 3) return null;

  let [first, second, third] = parts.map(Number);

  let year, month, day;

  if (parts[2].length === 4) {
    [month, day, year] = [first, second, third];
  } else if (parts[0].length === 4) {
    [year, month, day] = [first, second, third];
  } else if (parts[2].length === 2) {
    // Handles the case where year is two digits
    [month, day, year] = [first, second, third];
    year += year < 50 ? 2000 : 1900; // Convert to 4 digits
  } else {
    return null; // Unknown format
  }

  if (year >= 0 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
    const dt = DateTime.fromObject({ day, month, year });
    return dt.isValid ? dt : null;
  }

  return null;
};

export const tryParseWithPattern = (
  input: string,
  pattern: string
): DateTime | null => {
  const splitRegex = /[-/. :]/;

  pattern = pattern.replace(/,/g, "");
  input = input.replace(/,/g, "");

  let patternParts = [];
  let inputParts = [];

  if (pattern.includes("'T'")) {
    const [datePart, timePart] = pattern.split("'T'");
    const [inputDatePart, inputTimePart] = input.split("T");

    patternParts = [
      ...datePart.split(splitRegex),
      ...timePart.split(splitRegex),
    ];
    inputParts = [
      ...inputDatePart.split(splitRegex),
      ...inputTimePart.split(splitRegex),
    ];
  } else {
    patternParts = pattern.split(splitRegex);
    inputParts = input.split(splitRegex);
  }

  if (inputParts.length !== patternParts.length) return null;

  let year: number | undefined;
  let month: number | undefined;
  let day: number | undefined;
  let hour: number | undefined;
  let minute: number | undefined;
  let second: number | undefined;
  let millisecond: number | undefined;
  let meridiem: string | undefined;

  for (let i = 0; i < patternParts.length; i++) {
    const part = inputParts[i];
    const patternPart = patternParts[i];

    switch (patternPart) {
      case "yyyy":
        year = parseInt(part, 10);
        // consider that year input can be 2 digits or 4 digits
        if (year < 100) year += year < 50 ? 2000 : 1900;
        break;
      case "yy":
        year = parseInt(part, 10);
        year += year < 50 ? 2000 : 1900; // Convert to 4 digits
        break;
      case "MMMM":
        month = MONTH_NAMES.indexOf(part.toLowerCase()) + 1;
        break;
      case "MMM":
        month = MONTH_ABBREVIATIONS.indexOf(part.toLowerCase()) + 1;
        break;
      case "MM":
      case "M":
        month = parseInt(part, 10);
        break;
      case "dd":
      case "d":
        day = parseInt(part, 10);
        break;
      case "HH":
      case "H":
      case "hh":
      case "h":
        hour = parseInt(part, 10);
        break;
      case "mm":
        minute = parseInt(part, 10);
        break;
      case "ss":
        second = parseInt(part, 10);
        break;
      case "SSS":
        millisecond = parseInt(part, 10);
        break;
      case "a":
        meridiem = part.toUpperCase();
        break;
      default:
        return null;
    }
  }

  // Adjust for 12-hour clock
  if (meridiem === "PM" && hour && hour < 12) {
    hour += 12;
  } else if (meridiem === "AM" && hour === 12) {
    hour = 0;
  }

  if (
    year !== undefined &&
    !isNaN(year) &&
    month !== undefined &&
    !isNaN(month) &&
    day !== undefined &&
    !isNaN(day) &&
    month >= 1 &&
    month <= 12 &&
    day >= 1 &&
    day <= 31
  ) {
    const dt = DateTime.fromObject({
      year,
      month,
      day,
      hour: hour !== undefined && !isNaN(hour) ? hour : 0,
      minute: minute !== undefined && !isNaN(minute) ? minute : 0,
      second: second !== undefined && !isNaN(second) ? second : 0,
      millisecond:
        millisecond !== undefined && !isNaN(millisecond) ? millisecond : 0,
    });

    return dt.isValid ? dt : null;
  }
  return null;
};

export const callTryParseWithPattern = (userInput: string, pattern: string) => {
  try {
    return tryParseWithPattern(userInput, pattern);
  } catch (e) {
    return null;
  }
};

export const parseDate = (userInput: string, pattern: string) => {
  const res1 = callTryParseWithPattern(userInput, pattern);
  if (res1) return res1;
  const res2 = parseDateFromUserInput(userInput);
  if (res2) return res2;
};

export const parseDateTime = (userInput: string, pattern: string) => {
  const res1 = callTryParseWithPattern(userInput, pattern);
  if (res1) return res1;
  const res2 = parseDateTimeFromUserInput(userInput);
  if (res2) return res2;
};

export const parseTimeFromDateTimeorTime = (
  userInput: string,
  pattern: string
) => {
  const res1 = callTryParseWithPattern(userInput, pattern);
  if (res1) return res1;
  const res2 = parseTimeFromDateTimeorTimeInput(userInput);
  if (res2) return res2;
};

export const parseDateFromUserInput = (userInput: string): DateTime | null => {
  let datePart = userInput;

  const hasTimePart =
    userInput.indexOf(":") !== -1 && userInput.split(":").length > 1;

  if (hasTimePart) {
    const parts = userInput.trim().split(" ");
    datePart =
      parts.length === 2
        ? parts[0].indexOf(":") !== -1
          ? parts[1]
          : parts[0]
        : userInput;
  }

  const commonFormatResult = tryParseCommonDateFormats(datePart);
  if (commonFormatResult) return commonFormatResult;

  const cleanedInput = datePart
    .trim()
    .split(/[\s;|:.\/-]+/)
    .filter(Boolean)
    .map((word) => word.toLowerCase());

  let possibleDay: number | null = null,
    possibleMonth: number | null = null,
    possibleYear: number | null = null;

  for (const word of cleanedInput) {
    if (DAY_NAMES.includes(word) || DAY_ABBREVIATIONS.includes(word)) continue;

    let num = parseInt(word, 10);

    if (!possibleDay && num > 0 && num <= 31) {
      possibleDay = num;
      continue;
    }

    if (!possibleMonth) {
      let monthIndex = MONTH_NAMES.indexOf(word);
      if (monthIndex === -1) monthIndex = MONTH_ABBREVIATIONS.indexOf(word);

      if (monthIndex !== -1) {
        possibleMonth = monthIndex + 1;
        continue;
      } else if (num > 0 && num <= 12) {
        possibleMonth = num;
        continue;
      }
    }

    if (!possibleYear && num >= 0) {
      possibleYear = num < 100 ? num + (num < 50 ? 2000 : 1900) : num;
      continue;
    }
  }

  if (possibleDay && possibleMonth && possibleYear) {
    const dt = DateTime.fromObject({
      day: possibleDay,
      month: possibleMonth,
      year: possibleYear,
    });
    return dt.isValid ? dt : null;
  }

  return null;
};

export const parseTimeFromUserInput = (input) => {
  const cleanedInput = input
    .trim()
    .replace(/\s*:\s*/g, ":")
    .replace(/\s+(AM|PM|am|pm)\s*$/, " $1");
  const len = cleanedInput.length;
  if (len < 4 || len > 16) return null;

  let hour = 0,
    minute = 0,
    second = 0,
    millisecond = 0;
  let meridiem = null;

  let parts = cleanedInput.split(" ");
  if (parts.length > 2) return null;

  if (parts.length === 2) {
    meridiem = parts[1].toUpperCase();
    if (meridiem !== "AM" && meridiem !== "PM") return null;
  }

  let timeParts = parts[0].split(":");
  if (timeParts.length < 2 || timeParts.length > 3) return null;

  hour = parseInt(timeParts[0], 10);
  minute = parseInt(timeParts[1], 10);

  if (isNaN(hour) || isNaN(minute)) return null;

  if (timeParts.length === 3) {
    let secondParts = timeParts[2].split("."); // Split to consider milliseconds
    second = parseInt(secondParts[0], 10);
    if (secondParts.length === 2) {
      millisecond = parseInt(secondParts[1], 10);
    }
    if (isNaN(second) || isNaN(millisecond)) return null;
  }

  if (
    hour < 0 ||
    hour > 23 ||
    minute < 0 ||
    minute > 59 ||
    second < 0 ||
    second > 59 ||
    millisecond < 0 ||
    millisecond > 999
  )
    return null;

  if (meridiem === "PM" && hour < 12) {
    hour += 12;
  } else if (meridiem === "AM" && hour === 12) {
    hour = 0;
  }

  const dateTime = DateTime.fromObject({ hour, minute, second, millisecond });
  return dateTime.isValid ? dateTime : null;
};

export const parseTimeFromDateTimeorTimeInput = (userInput) => {
  const res1 = parseTimeFromUserInput(userInput);
  if (res1) return res1;
  const res2 = parseDateTimeFromUserInput(userInput);
  if (res2) return res2;
  return null;
};

export const parseDateTimeFromUserInput = (userInput) => {
  const fromISO = DateTime.fromISO(userInput);
  if (fromISO.isValid && userInput.includes("T")) return fromISO;

  const cleanedInput = userInput.trim().replace(/\s+/g, " ").toLowerCase();
  const words = cleanedInput.split(" ");

  let datePart = "";
  let timePart = "";
  let meridiem = "";

  for (const word of words) {
    if (word === "am" || word === "pm") {
      meridiem = word.toUpperCase();
      continue;
    }

    if (word.includes(":")) {
      timePart = word;
      continue;
    }

    datePart = `${datePart} ${word}`;
  }

  if (meridiem) {
    timePart = `${timePart} ${meridiem}`;
  }

  const dateObject = parseDateFromUserInput(datePart.trim());
  if (!dateObject || !dateObject.isValid) return null;

  const timeObject = parseTimeFromUserInput(timePart.trim());
  if (!timeObject || !timeObject.isValid) return null;

  if (!timeObject || !timeObject.isValid) return null;

  const combinedDateTime = DateTime.fromObject({
    year: dateObject.year,
    month: dateObject.month,
    day: dateObject.day,
    hour: timeObject.hour,
    minute: timeObject.minute,
    second: timeObject.second,
    millisecond: timeObject.millisecond,
  });

  return combinedDateTime.isValid ? combinedDateTime : null;
};
