import { Placement } from "@popperjs/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { MenuItem } from "..";
import { Div, DivCSSProps } from "../../styled/utils";
import { SelectMenu } from "./SelectMenu";
import { TextInput as TextInputBase } from "./TextInput";

const TextInput = styled(TextInputBase)(
  ({ theme: { colors, css: c } }) => css`
    ${(props: any) =>
      props.isDisabled &&
      css`
        cursor: not-allowed;
        pointer-events: none;
      `}
  `
);

const ClearIcon = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    pointer-events: auto;
  `
);

type ClickableAreaProps = {
  isInputDisabled: boolean;
};
const ClickableArea = styled(Div)<ClickableAreaProps>(
  ({ theme: { colors, css: c }, isInputDisabled }) => css`
    ${isInputDisabled &&
    css`
      :hover {
        cursor: pointer;
        opacity: 0.9;
      }
    `}
  `
);

export type Option = {
  label: any;
  value: any;
};

export type Props = {
  label?: any;
  labelTooltip?: string;
  placeholder?: string;
  value?: any;
  onChange: (v: string | null) => void;
  options: Option[];
  isInputDisabled?: boolean;
  isDisabled?: boolean;
  onToggle?: (isOpen: boolean) => void;
  placement?: Placement;
  icon?: JSX.Element;
  prefix?: string;
  disableClearIcon?: boolean;
  id?: string;
} & DivCSSProps;

const Autocomplete = ({
  label = null,
  isDisabled = false,
  isInputDisabled = false,
  placeholder = "general:autocomplete.placeholder",
  value,
  icon,
  onChange,
  placement,
  options,
  onToggle,
  id,
  disableClearIcon = false,
  labelTooltip,
  prefix,
  ...props
}: Props) => {
  const [updatedText, setUpdatedText] = useState(false);
  const selectedLabel = useMemo(
    () => options?.find((o) => o?.value === value)?.label || "",
    [value]
  );
  const [text, setText] = useState(selectedLabel);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== null && value !== undefined) setText(selectedLabel);
    else setText("");
  }, [value]);

  useEffect(() => {
    setUpdatedText(true);
  }, [text]);

  useEffect(() => {
    setUpdatedText(false);
    setText(selectedLabel);
    if (onToggle) onToggle(isMenuOpen);
  }, [isMenuOpen]);

  const onSelect = (v) => {
    setText(options.find((o) => o?.value === v)?.label);
    onChange(v);
    setIsMenuOpen(false);
  };

  const filteredOptions = useMemo(
    () =>
      updatedText
        ? options.filter((o) =>
            o.label.toLowerCase().includes(text.toLowerCase())
          )
        : options,
    [updatedText, text, options]
  );

  const inputIconUI =
    text && !disableClearIcon ? (
      <ClearIcon
        clickable
        dim={24}
        centered
        data-cy="clear-icon"
        id={id ? `${id}-clear` : null}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          onChange(undefined);
        }}
      >
        {clearIcon}
      </ClearIcon>
    ) : (
      <Div centered dim={24}>
        {icon || chevronDownIcon}
      </Div>
    );

  const textWithPrefix = `${prefix}${text}`;
  const shouldShowPrefix = prefix && !isTyping && !isMenuOpen;
  const inputValue = shouldShowPrefix ? textWithPrefix : text;

  return (
    <Div w100 {...props}>
      <ClickableArea
        isInputDisabled={isDisabled || isInputDisabled}
        onClick={() => {
          if (!isDisabled) setIsMenuOpen(true);
        }}
      >
        <TextInput
          setInputRef={setReferenceElement}
          id={id}
          onBlur={() => {
            if (isTyping && prefix) setIsTyping(false);
            if (filteredOptions.length === 0) {
              setText(selectedLabel);
              setIsMenuOpen(false);
            }
          }}
          label={label}
          isDisabled={isDisabled || isInputDisabled}
          placeholder={t(placeholder)}
          data-cy="option"
          value={inputValue}
          onChange={(v: string) => {
            if (!isTyping && prefix) setIsTyping(true);
            setText(v);
          }}
          icon={isDisabled ? null : inputIconUI}
          labelTooltip={labelTooltip}
        />
      </ClickableArea>

      {!isDisabled && filteredOptions.length > 0 && (
        <SelectMenu
          referenceElement={referenceElement}
          placement={placement}
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          {filteredOptions.map((o, index) => (
            <Div key={index} data-test-id="autocomplete-menu-item">
              <MenuItem
                id={id ? `${id}-option-${o.label}` : null}
                isSelected={value === o?.value}
                onClick={() => onSelect(o?.value)}
              >
                {o.label}
              </MenuItem>
            </Div>
          ))}
        </SelectMenu>
      )}
    </Div>
  );
};

const chevronDownIcon = (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9263 1L7.9631 7.96317L0.999928 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const clearIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4L12.5161 12.5161"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12.5161L12.5161 3.99998"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { Autocomplete };
