import { EnvironmentType } from "fuse-importer";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { api } from "../../api";
import { baseUrls } from "./index";

export class FuseApi {
  environment: EnvironmentType;
  apiToken: string;
  sessionToken: string | undefined;

  constructor(
    environment: EnvironmentType,
    apiToken: string,
    sessionToken?: string
  ) {
    this.environment = environment;
    this.apiToken = apiToken;
    this.sessionToken = sessionToken;
  }

  async initialize(templateSlug: string) {
    if (!this.sessionToken) {
      try {
        const response = await this.post<{ token: string }>(
          "/api/v1/importer/sessions",
          { importer_slug: templateSlug }
        );
        this.sessionToken = response.data.token;
      } catch (error) {
        console.error("Failed to fetch session token:", error);
      }
    }
  }

  private getAuthConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      toPublicApi: true,
      isImporterRequest: true,
      baseURL: baseUrls[this.environment],
      headers: {
        ...config?.headers,
        ...(this.sessionToken && {
          Authorization: `Bearer ${this.sessionToken}`,
        }),
      },
      params: {
        ...config?.params,
        ...(this.sessionToken ? {} : { api_token: this.apiToken }),
      },
    };
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.get(url, this.getAuthConfig(config));
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.post(url, data, this.getAuthConfig(config));
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.patch(url, data, this.getAuthConfig(config));
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.put(url, data, this.getAuthConfig(config));
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.delete(url, this.getAuthConfig(config));
  }
}
