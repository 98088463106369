const EnvironmentTypes = ["local", "production", "staging"] as const;

export type ImporterStylePreferences = {
  id: number;
  background: string;
  highlight1: string;
  highlight2: string;
  primary_color: string;
  secondary_color: string;
  logo_url: string;
};

export type EnvironmentType = typeof EnvironmentTypes[number];

export type SpreadsheetOptions = {
  batchValidationDelayMs?: number;
  batchValidationSize?: number;
  showCheckboxColumn?: boolean;
  withSingleRowSelect?: boolean;
};

export type ImporterOptions = {
  zIndex?: number;
  env?: EnvironmentType;
  previewMode?: boolean;
  loadingComponent?: () => JSX.Element;
  disableSubmitIfInvalid?: boolean;
  records?: Record[];
  language?: string;
  host?: string;
  modal?: boolean;
  cypress?: boolean;
  trackUploadedRecords?: boolean;
} & SpreadsheetOptions;

export enum FieldTypes {
  boolean = "boolean",
  integer = "integer",
  float = "float",
  string = "string",
  email = "email",
  enum = "enum",
  url = "url",
  date = "date",
  datetime = "datetime",
  time = "time",
}

export type FieldTypesAsUnion = keyof typeof FieldTypes;

export type SpreadsheetValidationErrors = {
  [rowId: string]: ValidationErrors;
};

export type Meta = {
  id: string;
  rowIndex: number;
  isInvalid?: boolean;
  hasWarning?: boolean;
  uploadedRow?: any;
  backendErrors?: ValidationErrors;
  backendWarnings?: ValidationErrors;
  transformedRow?: TransformedRecord;
  errors?: ValidationErrors;
  warnings?: ValidationErrors;
};

export type Record = {
  [key: string]: any;
  _meta?: Meta;
};

export type RecordDataSet = { [key: string]: Record };

export type ValidationResult = {
  errors: ValidationErrors;
  warnings: ValidationErrors;
};

export type ValidationErrors = { [k: string]: string };

export type OnValidateRecord = (
  record: Record
) => Promise<ValidationResult | ValidationErrors>;

export type FormatRecord = (record: Record) => Promise<Record> | Record;

export type RowValidationError = {
  rowId: string;
  errors: ValidationErrors;
};

export type BatchValidationErrors = {
  [rowId: string]: ValidationErrors;
};

export type ColumnWithCount = {
  [columnName: string]: number;
};

export type CalculateColumnCount = (
  oldErrors: ValidationErrors,
  newErrors: ValidationErrors
) => void;

export type TransformedObj = {
  original: any;
  transformed: any;
};

export type TransformedRecord = {
  [recordName: string]: TransformedObj;
};

export type AppResponse = {
  recordsToDisplay?: Record[];
  errors?: SpreadsheetValidationErrors;
  message?: string;
};

export type OnSubmit = (reviewedData: Record[]) => Promise<AppResponse>;
export type GetSessionToken = () => Promise<string>;

export type ValidationOptionKeys =
  | "pattern"
  | "max_length"
  | "min_length"
  | "length"
  | "min"
  | "max"
  | "less_than"
  | "before_date"
  | "greater_than"
  | "after_date";

export const validationTypesBase = [
  "cannot_contain",
  "contain",
  "max_length",
  "min_length",
  "length_exactly",
  "unique_case_sensitive",
  "unique_case_insensitive",
  "regex",
  "integer",
  "less_than",
  "greater_than",
  "even",
  "odd",
  "float",
  "email",
  "url",
  "boolean",
  "before_date",
  "after_date",
] as const;

export const mutableValidationTypes = [...validationTypesBase];

export type ValidationTypes = typeof validationTypesBase[number];

export type TransformationOptionKeys = "prefix" | "number_of_decimals";

export const transformationTypesBase = [
  "sentence_case",
  "capitalize",
  "uppercase",
  "lowercase",
  "trim_whitespace",
  "autoformat",
  "prefix",
  "number_of_decimals",
  "round",
  "floor",
  "ceiling",
] as const;

export const mutableTransformationTypes = [...transformationTypesBase];

export type TransformationTypes = typeof transformationTypesBase[number];

export interface FieldValidation {
  id?: number;
  validation_type?: ValidationTypes;
  is_default?: boolean;
  message?: string;
  options?: {
    [key in ValidationOptionKeys]?: any;
  };
}

export interface FieldTransformation {
  id?: number;
  transformation_type?: TransformationTypes;
  options?: {
    [key in TransformationOptionKeys]?: any;
  };
}

export type TemplateHeader = {
  label: string;
  required: boolean;
  unique: boolean;
  column_type: string;
  description?: string;
  internal_key: string;
  values: string[];
  pattern: string;
  validations: FieldValidation[];
  transformations: FieldTransformation[];
  position: number;
  id?: number;
  archived?: boolean;
};

type CARecordFn = (record: Record) => Promise<Record> | Record;

export type CustomActionTypes = "record";

// When we start adding more action types, we can add more handlers here
// instead of having directly 'never'
type CustomActionHandler<T extends CustomActionTypes> = T extends "record"
  ? CARecordFn
  : never;

export type CustomAction<T extends CustomActionTypes = CustomActionTypes> = {
  name: string;
  actionType: T;
  handler: CustomActionHandler<T>;
};

export type Organization = {
  id: number;
  name: string;
  trial_mode: boolean;
  plan: {
    title: string;
    allow_style_customization: boolean;
    has_cells_limit: boolean;
    included_cells: number;
  };
};

export type Integration = {
  url: string;
  trigger_type: string;
  active: boolean;
  provider: string;
};

export type ColumnMapping = {
  matched_column_names: string[];
  template_column_name: string;
  delimiter: string;
  id?: number;
};

export type ValueMapping = {
  matched_value: string;
  template_value: string;
  id?: number;
};

export type TemplateInfo = {
  columns: TemplateHeader[];
  integrations: Integration[];
  importer_style_preferences: ImporterStylePreferences;
  organization: Organization;
  name: string;
  preview_first_time: boolean;
  persistence: boolean;
};

export type Field = {
  name: string;
  label: string;
  description?: string;
  type: FieldTypes;
  isRequired?: boolean;
  options?: any;
  pattern?: string;
  validations?: FieldValidation[];
  transformations?: FieldTransformation[];
};

// Add a column feature
export type ColumnRequiredProps<T extends FieldTypesAsUnion> = Partial<
  Pick<TemplateHeader, "unique" | "position" | "pattern">
> & {
  internal_key: string;
  label: string;
  column_type: T;
  description?: string;
  required: boolean;
  validations?: ColumnValidations<T>[];
  transformations?: ColumnTransformations<T>[];
} & (T extends "enum"
    ? EnumOptions
    : T extends "time"
    ? TimeOptions
    : OtherOptions);

export type ValidationTypesRules<
  T extends FieldTypesAsUnion
> = T extends "integer"
  ? IntegerValidationRule
  : T extends "float"
  ? FloatValidationRule
  : T extends "string"
  ? StringValidationRule
  : T extends "email"
  ? EmailValidationRule
  : T extends "enum"
  ? EnumValidationRule
  : T extends "url"
  ? UrlValidationRule
  : T extends "boolean"
  ? BooleanValidationRule
  : T extends "date"
  ? DateValidationRule
  : T extends "datetime"
  ? DateTimeValidationRule
  : T extends "time"
  ? TimeValidationRule
  : never;

export type TransformationTypesRules<
  T extends FieldTypesAsUnion
> = T extends "integer"
  ? IntegerTransformationRule
  : T extends "float"
  ? FloatTransformationRule
  : T extends "string"
  ? StringTransformationRule
  : T extends "url"
  ? UrlTransformationRule
  : T extends "date" | "datetime" | "time"
  ? DateDateTimesAndTimesTransformationRule
  : never;

export type ValidationTypeOptions<
  T extends ValidationTypesRules<FieldTypesAsUnion>
> = T extends "cannot_contain" | "contain" | "regex"
  ? { pattern: string }
  : T extends "max_length"
  ? { max_length: number }
  : T extends "min_length"
  ? { min_length: number }
  : T extends "length_exactly"
  ? { length: number }
  : T extends "less_than"
  ? { less_than: string | number }
  : T extends "before_date"
  ? { before_date: string | number | Date }
  : T extends "greater_than"
  ? { greater_than: string | number }
  : T extends "after_date"
  ? { after_date: string | number | Date }
  : never;

export type TransformationTypeOptions<
  T extends TransformationTypesRules<FieldTypesAsUnion>
> = T extends "prefix"
  ? { prefix: string }
  : T extends "number_of_decimals"
  ? { number_of_decimals: number }
  : never;

export interface ColumnValidations<T extends FieldTypesAsUnion> {
  validation_type: ValidationTypesRules<T>;
  message: string;
  options?: ValidationTypeOptions<ValidationTypesRules<T>>;
}

export interface ColumnTransformations<T extends FieldTypesAsUnion> {
  transformation_type: TransformationTypesRules<T>;
  options?: TransformationTypeOptions<TransformationTypesRules<T>>;
}

type EnumOptions = {
  values: string[];
};

type TimeOptions = {
  values?: never;
};

type OtherOptions = {
  values?: never;
};

type StringValidationRule =
  | "cannot_contain"
  | "contain"
  | "max_length"
  | "min_length"
  | "length_exactly"
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "regex";
type StringTransformationRule =
  | "sentence_case"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "trim_whitespace";
type IntegerValidationRule =
  | "less_than"
  | "greater_than"
  | "even"
  | "odd"
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "regex";
type IntegerTransformationRule = "round" | "floor" | "ceiling";
type FloatValidationRule =
  | "less_than"
  | "greater_than"
  | "even"
  | "odd"
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "regex";
type FloatTransformationRule = "number_of_decimals";
type EmailValidationRule =
  | "cannot_contain"
  | "contain"
  | "max_length"
  | "min_length"
  | "length_exactly"
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "regex";
type EnumValidationRule = "unique_case_sensitive" | "unique_case_insensitive";
type UrlValidationRule =
  | "cannot_contain"
  | "contain"
  | "max_length"
  | "min_length"
  | "length_exactly"
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "regex";
type UrlTransformationRule = "prefix";
type BooleanValidationRule = "boolean";
type DateValidationRule =
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "before_date"
  | "after_date";
type DateTimeValidationRule =
  | "unique_case_sensitive"
  | "unique_case_insensitive"
  | "before_date"
  | "after_date";
type TimeValidationRule = "unique_case_sensitive" | "unique_case_insensitive";
type DateDateTimesAndTimesTransformationRule = "autoformat";

export type DuplicateRecordIds = Set<string>;

export type DuplicateRecordHash = {
  id: string;
  hash: string;
};

export type DuplicateValuesCollection = {
  field?: {
    value?: string[];
  };
};
