import { useUserContext } from "components/UserContextProvider";
import Form, { FormikSumbitButton } from "components/common/form/Form";
import { yupEmail } from "components/common/form/validations";
import { Autocomplete, Button, Div, Modal, api, colors } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { launchImporter } from "../TemplateRoutes/common/utils";

const emailField = {
  label: "Email Address",
  name: "email",
  placeholder: "E.g. john.doe@gmail.com",
  initialValue: "",
  validation: yupEmail,
};

const FormButtonsWrapper = styled(Div)`
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;

type ImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onImportClose: () => void;
};

const ImportFileModal = ({
  isOpen,
  onClose,
  onImportClose,
}: ImportModalProps) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { userDetails } = useUserContext();

  const getImporters = async () => {
    try {
      const { data } = await api.get(`/api/v1/templates/all`);
      const options = data.map((t) => {
        return { value: t.id, label: t.name, ...t };
      });
      setTemplateOptions(options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!templateOptions || templateOptions.length === 0) return;
    if (templateOptions && templateOptions.length === 1)
      setSelectedTemplate(templateOptions[0]);
  }, [templateOptions]);

  useEffect(() => {
    getImporters();
  }, []);

  const launchTemplate = async () => {
    if (!selectedTemplate) return;
    const template = templateOptions.find((o) => o.id === selectedTemplate.id);
    if (!template) return;

    const { slug, name, webhook_url } = template;
    launchImporter({
      template: { slug, name, webhook_url },
      onCloseCallback: onImportClose,
      meta: userDetails,
    });
    onClose();
  };

  const contentUI = (
    <>
      <Div textAlignCenter mb={30}>
        <Div h5 c={colors.textPrimary}>
          Import a File
        </Div>
        <Div wbody3>Select an importer to get started.</Div>
      </Div>
      <Form fields={[]} onSubmit={launchTemplate}>
        <>
          <Autocomplete
            value={selectedTemplate?.value}
            options={templateOptions}
            label="Select Importer"
            placement="bottom"
            placeholder="Select an option..."
            onChange={(v) => {
              setSelectedTemplate(templateOptions.find((o) => o.id === v));
            }}
          />
          <FormButtonsWrapper>
            <Button
              mr={16}
              w={104}
              variant="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <FormikSumbitButton
              w={200}
              disableOnErrors={true}
              isDisabled={!selectedTemplate}
              data-cy={"launch-import-button"}
            >
              <Div>Launch Importer</Div>
            </FormikSumbitButton>
          </FormButtonsWrapper>
        </>
      </Form>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Div p={22}>{contentUI}</Div>
    </Modal>
  );
};

export default ImportFileModal;
