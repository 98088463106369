import FuseImporter, {
  EnvironmentType,
  Record,
  ValidationErrors,
  ValidationResult,
} from "fuse-importer";
import { segmentProvidedValue } from "../../../../SegmentContextProvider";
import LocalFuseImporter from "./LocalFuseImporter";
import { api } from "fuse-shared-ui";

export const launchImporter = ({
  template,
  meta = {},
  onCloseCallback = () => {},
}) => {
  const env: EnvironmentType = process.env
    .REACT_APP_IMPORTER_PREVIEW_ENVIRONMENT as EnvironmentType;
  const trackData = {
    template_name: template.name,
    template_id: template.slug,
  };
  if (segmentProvidedValue) {
    segmentProvidedValue.track("user_launching_template", trackData);
  }
  const importer =
    process.env.REACT_APP_CYPRESS === "true"
      ? new LocalFuseImporter({ options: { env: env } })
      : new FuseImporter({ options: { env: env } });
  importer.onSubmit = window?.["cypressOnSubmit"];
  const onValidateRecord = async (
    record: Record
  ): Promise<ValidationResult> => {
    const errors: ValidationErrors = {};
    const warnings: ValidationErrors = {};
    return { errors: errors, warnings: warnings };
  };
  importer.onValidateRecord = onValidateRecord;
  importer.onClose = onCloseCallback;
  importer.getSessionToken = async () => {
    try {
      const response = await api.post("/api/v1/importer/webapp_sessions", {
        importer_slug: template.slug,
        metadata: meta,
      });
      return response.data.token;
    } catch (error) {
      console.error("Failed to fetch session token:", error);
    }
  };
  importer.show();
};
