import { Record } from "fuse-importer";
import { useSpreadsheetContext } from "../../../Importer";

const useFindAndReplace = () => {
  const { fields } = useSpreadsheetContext();
  const replaceTextWithRegex = (
    value: string,
    searchText: string,
    replaceText: string,
    matchCase: boolean
  ) => {
    const regex = new RegExp(searchText, matchCase ? "g" : "ig");
    return value.replace(regex, replaceText);
  };

  const replaceCaseInsensitive = (
    str: string,
    searchText: string,
    rby: string
  ) => {
    const pos = str.toLowerCase().indexOf(searchText.toLowerCase());
    return pos == -1
      ? str
      : str.substring(0, pos) + rby + str.substring(pos + searchText.length);
  };

  const replaceTextWithoutRegex = (
    value: string,
    searchText: string,
    replaceText: string,
    matchCase: boolean
  ) => {
    if (matchCase) {
      return value.split(searchText).join(replaceText);
    } else {
      return replaceCaseInsensitive(value, searchText, replaceText);
    }
  };

  const replaceInRecord = (
    record: Record,
    searchText: string,
    replaceText: string,
    matchCase: boolean,
    useRegex: boolean
  ) => {
    let textFound = false;
    fields.forEach((f) => {
      if (!record[f.name]) return;
      let newValue;

      if (useRegex) {
        newValue = replaceTextWithRegex(
          record[f.name].toString(),
          searchText,
          replaceText,
          matchCase
        );
      } else {
        newValue = replaceTextWithoutRegex(
          record[f.name].toString(),
          searchText,
          replaceText,
          matchCase
        );
      }

      if (record[f.name] !== newValue) {
        record[f.name] = newValue;
        textFound = true;
      }
    });
    return textFound;
  };

  return {
    replaceInRecord,
  };
};

export default useFindAndReplace;
