/* eslint-disable import/no-anonymous-default-export */
export default {
  preview: {
    title: "You're about to preview your template.",
    description:
      "Download our example CSV to try out your importer. We have randomly generated it so you can see how Fuse transforms the data..",
    download: "Download CSV",
    note: {
      title: "Note:",
      description:
        "This screen will not exist once you install the importer on your website.",
    },
  },
  upload: {
    headers_preview: {
      title: "Select a Header Row",
      description: "File must contain column headers to proceed.",
      cancel: "Re-upload file",
      continue: "Continue",
    },
    error: {
      title: "Something’s wrong",
    },
    header: {
      title: "Make sure your document contains data similar to this:",
      required_headers: "Required Headers",
      optional_headers: "Optional Headers",
    },
    title: "Upload a File to Import your Data",
    description: `
    Upload any .csv, .xls, .xlsx file with any set of
    columns as long as it has one record per row.
  `,
    loading: {
      title: "Uploading...",
    },
    dropzone: {
      title: "Drag & drop your file here",
      description: "or",
      button: "Browse Files",
      enter_data_manually: "Enter data manually",
    },
  },
  match: {
    title: "Match Your Columns with Ours",
    description:
      "{{numberOfMatchedHeaders}} of {{totalNumberOfHeaders}} columns have been matched.",
    enum_description: `
    Our system only accepts certain values for some columns. Below you can
    match the values from your spreadsheet to the values we accept.
  `,
    columns: {
      uploaded_columns: "Uploaded Columns",
      supported_columns: "Supported Columns",
      uploaded_values: "Uploaded Values",
      supported_values: "Supported Values",
      content_example: "Content example",
      merge_column_tooltip: "Merge multiple columns into {{column}}",
      status: "Status",
      placeholder: "Select an option...",
      values_for_the_column: "Values for the {{column}} Column",
      matched: "Matched",
      unmatched: "Unmatched",
      combine_data: {
        title: "Combine Data for {{column}}",
        description: "Combine data from 2 or more columns into 1 column",
        select_columns: "Select columns to combine",
        choose_delimeter: "Choose a delimiter to add between the columns",
        data_preview: "Data Preview",
        success_toast: "Columns combined successfully",
        cancel_button: "Cancel",
        combine_button: "Combine your columns",
      },
    },
  },
  review: {
    title: "Review & Submit",
    manual_importer_title: "Enter Data & Submit",
    export: "Export",
    description:
      "Some cells in the document might have errors in format or missing information. Please review these to continue.",
    table_actions: {
      filters_by_rows: {
        all_rows: "All Rows",
        invalid_rows: "Invalid",
        duplicates: "Duplicates",
      },
      search_rows: {
        tooltip: "Search something",
        placeholder: "Search something...",
        number_of_results: "{{length}} results",
      },
      filter_by_errors: {
        search_or_select: "Search or select",
        filter_by_errors: "Filter by errors",
      },
      delete_rows: {
        confirmation_modal: "Are you sure you want to delete {{length}} row",
        modal_title: "Delete Selected Row",
        yes_delete: "Yes, delete",
        select_rows: "Select rows to delete",
        delete_row: "Delete Row",
        deleted_message: "{{length}} rows deleted",
      },
      export_your_data: {
        tooltip: "Export your data",
      },
      transformed_records: {
        auto_corrections_done: "cells auto-corrected",
        example: "Example",
        auto_corrections_amount: "We have auto-corrected {{amount}} issues.",
      },
      custom_actions: {
        label: "More actions",
        general_error_toast:
          "It was not possible to run the action. Please try again.",
        error_toast:
          'Error occurred while running action "{{actionName}} on selected rows". Please check the code implementation and try again.',
        success_toast:
          'Action "{{actionName}}" was executed successfully on the selected rows.',
      },
    },
    persistence: {
      loader_modal: {
        title: "Submitting Import...",
        subtitle: "Please wait. Window will close when processing is complete",
        leave_warning:
          "Are you sure you want to leave? Your changes may not be saved.",
      },
      response_modal: {
        success_header: "Data submitted successfully!",
        success_default:
          "Your data has been submitted successfully. Click below to continue.",
        error_default:
          "Some records had errors while being imported. Please review and re-submit records with errors.",
        close_modal_button: "OK, close",
      },
      retry_modal: {
        title: "Something went wrong while submitting Import",
        description: "No worries, please try submitting the import again.",
        cancel: "Go back",
        try_again: "Try again",
      },
    },
  },
  general: {
    autocomplete: {
      placeholder: "Select an option...",
    },
    multi_select: {
      max_limit_error: "You can only select up to 10 options",
    },
    steps: {
      upload: "Upload",
      match: "Match",
      review: "Review",
    },
    stepper: {
      go_back: "Go back",
      continue: "Continue",
      submit: "Submit",
    },
    find_and_replace_modal: {
      cancel: "Cancel",
      description: "Enter the text you want to find and replace.",
      find: "Find",
      no_records_found: "Text could not be found in any cells.",
      records_replaced: "{{length}} row(s) have been updated.",
      replace_with: "Replace With",
      submit: "Find and Replace",
      title: "Find & Replace",
      use_case_sensitive: "Use case sensitive matching",
      use_regex: "Use regular expression matching",
    },
    unmatched_headers_modal: {
      title: "Unmatched Column Headers",
      description_singular:
        "There is {{count}} unmatched column headers. You will have to fill these out manually if you proceed.",
      description_plural:
        "There are {{count}} unmatched column headers. You will have to fill these out manually if you proceed.",
      assign_headers: "Assign Headers",
      skip: "Skip",
    },
    submit_modal: {
      title: "Ready to submit?",
      description:
        "We didn't find anything wrong with your file. You're good to go!",
      submit: "Yes, submit",
      cancel: "Go back",
    },
    confirm_close_modal: {
      title: "Are you sure?",
      description: "Closing the importer will lose any changes.",
      close: "Close",
      cancel: "Cancel",
    },
    preview_mode_banner: {
      title: "You’re currently in Preview mode.",
    },
    warnings_banner: {
      trial_mode: {
        info_text:
          "This account is in trial mode. Imports are limited to 100 records.",
        button_text: "Select plan now",
      },
      invalid_submit_fn: {
        info_text:
          "Submit is disabled. Turn off Incognito Mode or add an onSubmit hook to enable submit.",
        button_text: "How to do it?",
      },
    },
  },
};
