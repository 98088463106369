import styled, { css } from "styled-components";
import {
  addToast,
  Autocomplete as AutoCompleteBase,
  Button,
  Modal as ModalBase,
  ModalWrapper,
  SelectMenuScrollArea,
  TooltipProvider,
} from "../../../common";
import { Div } from "../../../styled";
import { Multiselect } from "../../../common/inputs/Multiselect";
import { useState } from "react";
import { useImporterContext } from "../../contexts/ImporterContextProvider";
import {
  delimiterMap,
  useDeleteTemplateHeaderMatching,
  useRowPreview,
} from "./common";
import { useTranslation } from "react-i18next";

const Autocomplete = styled(AutoCompleteBase)(
  () => css`
    ${SelectMenuScrollArea} {
      padding: 0;
      max-height: 238px;
    }
  `
);

const Modal = styled(ModalBase)(
  ({ theme: { colors, css: c } }) => css`
    ${ModalWrapper} {
      width: 710px;
      box-sizing: border-box;
      padding: 80px 50px 42px 50px;
    }
  `
);

type DataPreviewCellProps = {
  index: number;
};
const DataPreviewCell = styled(Div)<DataPreviewCellProps>`
  margin: 0px;
  height: 34px;
  padding: 6px 12px;
  box-sizing: border-box;
  ${(p) =>
    p.index === 0 &&
    css`
      border-top: 1px solid ${(p) => p.theme.colors.gray300};
    `}
  border-bottom: 1px solid ${(p) => p.theme.colors.gray300};
  border-right: 1px solid ${(p) => p.theme.colors.gray300};
  border-left: 1px solid ${(p) => p.theme.colors.gray300};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

type CombineDataModalProps = {
  onClose: () => void;
  templateColumnLabel: string;
};
export const CombineDataModal = ({
  onClose,
  templateColumnLabel,
}: CombineDataModalProps) => {
  const {
    headersMappings,
    setHeadersMappings,
    uploadedDataHeaders,
  } = useImporterContext();
  const { deleteTempleteHeaderMatching } = useDeleteTemplateHeaderMatching();
  const { t } = useTranslation("match");

  const selectedColumns =
    headersMappings[templateColumnLabel]?.matched_headers || [];
  const selectedDelimeter =
    headersMappings[templateColumnLabel]?.delimiter || delimiterMap["Space"];

  const [combinedColumns, setCombinedColumns] = useState(selectedColumns);
  const [delimeter, setDelimeter] = useState(selectedDelimeter);
  const { getRowPreview } = useRowPreview(combinedColumns, delimeter);
  const isButtonDisabled = combinedColumns.length === 0;

  const headerUI = (
    <Div>
      <Div centered h2 data-cy="combine-column-heading">
        {t("columns.combine_data.title", { column: templateColumnLabel })}
      </Div>
      <Div centered body1 data-cy="combine-column-sub-heading" mt={8}>
        {t("columns.combine_data.description")}
      </Div>
    </Div>
  );

  const onDelimeterChange = (value) => {
    setDelimeter(value);
  };

  const onChangeCombinedColumns = (value) => {
    setCombinedColumns(value);
  };

  const onSubmit = () => {
    if (isButtonDisabled) return;
    deleteTempleteHeaderMatching(templateColumnLabel);
    setHeadersMappings({
      ...headersMappings,
      [templateColumnLabel]: {
        matched_headers: combinedColumns,
        delimiter: delimeter,
      },
    });
    addToast(t("columns.combine_data.success_toast"), "success");
    onClose();
  };

  const dataPreview = [getRowPreview(0), getRowPreview(1), getRowPreview(2)];
  const dataPreviewUI = (
    <Div mt={16}>
      <Div body4 mt={28} mb={16}>
        {t("columns.combine_data.data_preview")}
      </Div>
      {dataPreview.map((row, i) => {
        const htmlRowString = row?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");

        return (
          <TooltipProvider
            tooltip={
              <div dangerouslySetInnerHTML={{ __html: htmlRowString }} />
            }
            placement="auto"
            centered
          >
            <DataPreviewCell
              key={i}
              index={i}
              w100
              data-cy={`combine-data-preview-${i}`}
              dangerouslySetInnerHTML={{ __html: htmlRowString }}
            />
          </TooltipProvider>
        );
      })}
    </Div>
  );

  const buttonsUI = (
    <Div justifyEnd w100 mt={58}>
      <Div dflex h={52}>
        <Button
          data-cy="cancel"
          w={104}
          mr={16}
          variant="secondary"
          onClick={onClose}
        >
          {t("columns.combine_data.cancel_button")}
        </Button>
        <Button
          w={235}
          isDisabled={combinedColumns.length < 2}
          onClick={onSubmit}
          data-cy="modal-combine-columns-button"
        >
          {t("columns.combine_data.combine_button")}
        </Button>
      </Div>
    </Div>
  );

  return (
    <Modal isOpen={true} onClose={onClose}>
      {headerUI}
      <Multiselect
        data-cy="combine-columns-multiselect"
        mt={42}
        label={t("columns.combine_data.select_columns")}
        value={combinedColumns}
        options={uploadedDataHeaders.map((h) => ({
          value: h,
          label: h,
        }))}
        onChange={onChangeCombinedColumns}
      />
      <Autocomplete
        data-cy="delimeter-autocomplete"
        mt={42}
        disableClearIcon
        label={t("columns.combine_data.choose_delimeter")}
        onChange={onDelimeterChange}
        value={delimeter}
        placement="bottom"
        options={Object.keys(delimiterMap).map((d) => ({
          value: delimiterMap[d],
          label: d,
        }))}
      />
      {dataPreviewUI}
      {buttonsUI}
    </Modal>
  );
};
