import { Placement } from "@popperjs/core";
import { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../../styled/utils";
import {
  SelectMenu as SelectMenuBase,
  SelectMenuScrollArea,
} from "./SelectMenu";
import { addToast } from "../Toast";
import { useTranslation } from "react-i18next";

const SelectMenu = styled(SelectMenuBase)(
  ({ theme: { colors, css: c } }) => css`
    ${SelectMenuScrollArea} {
      padding: 0;
      max-height: 245px;
    }
  `
);

type MenuItemProps = {
  isSelected?: boolean;
};
export const MultiselectItem = styled(Div)<MenuItemProps>`
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.color || props.theme.colors.text};
  ${(props) => props.theme.css.body1}
  ${(p) => p.theme.css.selectNone};
  ${(p) =>
    p.isSelected &&
    css`
      color: ${(p) => p.theme.colors.blue500};
    `}
`;

const ClickableArea = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    :hover {
      cursor: pointer;
      opacity: 0.9;
    }
  `
);

type InputContainerProps = {
  menuIsOpen: boolean;
};
const InputContainer = styled(Div)<InputContainerProps>`
  border-radius: 8px;
  margin: 0px;
  padding: 16px 16px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  box-sizing: border-box;
  ${(p) =>
    p.menuIsOpen &&
    css`
      outline: 2px solid ${(props) => props.theme.colors.blue500};
      outline-offset: -1px;
      box-shadow: 0px 0px 8px ${(props) => props.theme.colors.blue500}3d;
    `}
`;

const Pill = styled(Div)`
  border-radius: 35px;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  background: ${(props) => props.theme.colors.gray150};
  padding: 0 16px;
  white-space: nowrap;
`;

const PillsContainer = styled(Div)`
  overflow: auto;
  width: 600px;
  height: 34px;
`;

export type Option = {
  label: any;
  value: any;
};

export type Props = {
  label?: any;
  labelTooltip?: string;
  placeholder?: string;
  value?: any;
  onChange: (value: string[]) => void;
  options: Option[];
  isInputDisabled?: boolean;
  isDisabled?: boolean;
  onToggle?: (isOpen: boolean) => void;
  placement?: Placement;
  icon?: JSX.Element;
  prefix?: string;
  id?: string;
} & DivCSSProps;

const Multiselect = ({
  label = null,
  isDisabled = false,
  isInputDisabled = false,
  placeholder = "general:autocomplete.placeholder",
  value,
  icon,
  onChange,
  placement,
  options,
  onToggle,
  id,
  labelTooltip,
  prefix,
  ...props
}: Props) => {
  const [updatedText, setUpdatedText] = useState(false);
  const selectedLabel = useMemo(
    () => options?.find((o) => o?.value === value)?.label || "",
    [value]
  );
  const [text, setText] = useState(selectedLabel);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (value !== null && value !== undefined) setText(selectedLabel);
    else setText("");
  }, [value]);

  useEffect(() => {
    setUpdatedText(true);
  }, [text]);

  useEffect(() => {
    setUpdatedText(false);
    setText(selectedLabel);
    if (onToggle) onToggle(isMenuOpen);
  }, [isMenuOpen]);

  const onMenuItemClick = (v: string, isSelected: boolean) => {
    const updateSelection = (newValue: string[]) => {
      newValue.sort((a, b) => {
        return (
          options.findIndex((o) => o.value === a) -
          options.findIndex((o) => o.value === b)
        );
      });
      onChange(newValue);
    };
    if (isSelected) {
      const newValue = value.filter((val) => val !== v);
      updateSelection(newValue);
    } else {
      if (value.length >= 10) {
        addToast(t("general:multi_select.max_limit_error"), "error");
        return;
      }
      const newValue = [...value, v];
      updateSelection(newValue);
    }
  };

  const onRemoveItem = (item) => {
    const newValue = value.filter((val) => val !== item);
    onChange(newValue);
  };

  const filteredOptions = useMemo(
    () =>
      updatedText
        ? options.filter((o) =>
            o.label.toLowerCase().includes(text.toLowerCase())
          )
        : options,
    [updatedText, text, options]
  );

  const pillsUI = (
    <InputContainer menuIsOpen={isMenuOpen} ref={setReferenceElement}>
      <Div data-cy="input-field" spaceBetween alignCenter>
        <PillsContainer dflex mr={16}>
          {value.map((item, i) => (
            <Pill key={item} ml={i === 0 ? 0 : 8} alignCenter>
              {item}
              <Div
                data-cy="remove-item"
                clickable
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemoveItem(item);
                }}
                centered
                ml={8}
              >
                {clearIcon}
              </Div>
            </Pill>
          ))}
        </PillsContainer>
        <Div>{chevronDownIcon}</Div>
      </Div>
    </InputContainer>
  );

  return (
    <Div {...props}>
      <Div body4 mb={8}>
        {label}
      </Div>
      <ClickableArea onClick={() => setIsMenuOpen(true)}>
        {pillsUI}
      </ClickableArea>

      {!isDisabled && filteredOptions.length > 0 && (
        <SelectMenu
          data-cy="multiselect-menu"
          referenceElement={referenceElement}
          placement={placement}
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          {filteredOptions.map((o, index) => {
            const isSelected = value.includes(o.value);
            return (
              <MultiselectItem
                key={o.value}
                id={id ? `${id}-option-${o.label}` : null}
                spaceBetween
                clickable
                isSelected={isSelected}
                onClick={() => onMenuItemClick(o?.value, isSelected)}
              >
                <Div>{o.label}</Div>
                <Div data-cy={isSelected ? "minus-icon" : "plus-icon"} centered>
                  {isSelected ? removeIcon : addIcon}
                </Div>
              </MultiselectItem>
            );
          })}
        </SelectMenu>
      )}
    </Div>
  );
};

const chevronDownIcon = (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9263 1L7.9631 7.96317L0.999928 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const clearIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4L12.5161 12.5161"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12.5161L12.5161 3.99998"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const addIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.5V17.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 10L17.5 10"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const removeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 10L17.5 10"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export { Multiselect };
