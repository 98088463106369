/* eslint-disable import/no-anonymous-default-export */
export default {
  preview: {
    title: "Estás a punto de previsualizar tu importador.",
    description:
      "Descarga nuestro ejemplo CSV para probar tu importador. Lo hemos generado aleatoriamente para que veas cómo Fuse transforma los datos.",
    download: "Descargar CSV",
    note: {
      title: "Nota:",
      description:
        "Esta pantalla no existirá una vez que instales el importador en tu sitio web.",
    },
  },
  upload: {
    headers_preview: {
      title: "Seleccione una fila de encabezado",
      description:
        "El archivo debe contener encabezados de columna para continuar.",
      cancel: "No, vuelva a cargar el archivo",
      continue: "Sí, continuar",
    },
    error: {
      title: "Algo está mal",
    },
    header: {
      title: "Asegúrate de que tu documento contenga datos similares a estos:",
      required_headers: "Encabezados requeridos",
      optional_headers: "Encabezados opcionales",
    },
    title: "Cargue un archivo para importar sus datos",
    description: `
    Cargue cualquier archivo de hoja de cálculo .csv, .xls, .xlsx con cualquier conjunto de
    columnas siempre y cuando tenga un registro por fila.
  `,
    loading: {
      title: "Cargando...",
    },
    dropzone: {
      title: "Arrastra y suelta tu archivo aquí",
      description: "o",
      button: "Examinar archivos",
      enter_data_manually: "Ingresar datos manualmente",
    },
  },
  match: {
    title: "Empareja tus columnas con las nuestras",
    description:
      "¡Fue posible emparejar {{numberOfMatchedHeaders}} de {{totalNumberOfHeaders}} columnas correctamente!",
    enum_description: `
    Nuestro sistema solo acepta ciertos valores para algunas columnas. A continuación, puede
    emparejar los valores de su hoja de cálculo con los valores que aceptamos.
  `,
    columns: {
      uploaded_columns: "Columnas cargadas",
      supported_columns: "Columnas admitidas",
      uploaded_values: "Valores cargados",
      supported_values: "Valores admitidos",
      content_example: "Ejemplo de contenido",
      merge_column_tooltip: "Combinar varias columnas en {{column}}",
      status: "Estado",
      placeholder: "Seleccione una opción...",
      values_for_the_column: "Valores para la columna {{column}}",
      matched: "Emparejado",
      unmatched: "No emparejado",
      combine_data: {
        title: "Combinar datos para {{column}}",
        description:
          "Combine los datos de las columnas seleccionadas en una sola columna.",
        cancel_button: "Cancelar",
        combine_button: "Combinar",
        delimiter: "Delimitador",
        success_toast: "Columnas combinadas con éxito",
        data_preview: "Vista previa de datos",
        select_columns: "Seleccione las columnas para combinar",
      },
    },
  },
  review: {
    title: "Revisar y Enviar",
    manual_importer_title: "Ingresar Datos y Enviar",
    export: "Exportar",
    description:
      "Algunas celdas en el documento pueden tener errores en el formato o información faltante. Por favor revise esto para continuar.",
    table_actions: {
      filters_by_rows: {
        all_rows: "Todas las Filas",
        invalid_rows: "Filas Inválidas",
        duplicates: "Duplicadas",
      },
      search_rows: {
        tooltip: "Buscar algo",
        placeholder: "Buscar algo...",
      },
      filter_by_errors: {
        search_or_select: "Buscar o seleccionar",
        filter_by_errors: "Filtrar por errores",
      },
      delete_rows: {
        confirmation_modal: "¿Seguro que quieres eliminar {{length}} fila",
        modal_title: "Eliminar la fila seleccionada",
        yes_delete: "Sí, eliminar",
        select_rows: "Seleccione las filas a eliminar",
        delete_row: "Eliminar fila",
        deleted_message: "{{length}} filas eliminadas",
      },
      export_your_data: {
        tooltip: "Exporta tus datos",
      },
      transformed_records: {
        auto_corrections_done: "celdas corregidas automáticamente",
        example: "Ejemplo",
        auto_corrections_amount:
          "Hemos corregido automáticamente {{amount}} problemas.",
      },
      custom_actions: {
        label: "Más acciones",
        general_error_toast:
          "No fue posible ejecutar la acción. Por favor, inténtalo nuevamente.",
        error_toast:
          'Se produjo un error al ejecutar la acción "{{actionName}}" en las filas seleccionadas. Por favor, revisa la implementación del código y vuelve a intentarlo.',
        success_toast:
          'La acción "{{actionName}}" se ejecutó correctamente en las filas seleccionadas.',
      },
    },
    persistence: {
      loader_modal: {
        title: "Enviando Importación...",
        subtitle:
          "Por favor espera. La ventana se cerrará cuando el proceso esté completo",
        leave_warning:
          "¿Seguro que quieres salir? Es posible que los cambios no se guarden.",
      },
      response_modal: {
        success_header: "Datos enviados con éxito!",
        success_default:
          "Tus datos han sido enviados con éxito. Haz clic abajo para continuar.",
        error_default:
          "Algunos registros tuvieron errores durante la importación. Por favor, revise y vuelva a enviar los registros con errores.",
        close_modal_button: "OK, cerrar",
      },
      retry_modal: {
        title: "Algo salió mal al enviar la importación",
        description: "No te preocupes, intenta enviar la importación de nuevo.",
        cancel: "Volver",
        try_again: "Intentar de nuevo",
      },
    },
  },
  general: {
    autocomplete: {
      placeholder: "Seleccione una opción...",
    },
    multi_select: {
      max_limit_error: "No puedes seleccionar más de 10 opciones",
    },
    steps: {
      upload: "Subir",
      match: "Emparejar",
      review: "Revisión",
    },
    stepper: {
      go_back: "Volver",
      continue: "Continuar",
      submit: "Enviar",
    },
    unmatched_headers_modal: {
      title: "Encabezados de columna no emparejados",
      description_singular:
        "Hay {{count}} encabezados de columna no emparejados. Tendrás que completar estos manualmente si procedes.",
      description_plural:
        "Hay {{count}} encabezados de columna no emparejados. Tendrás que completar estos manualmente si procedes.",
      assign_headers: "Asignar encabezados",
      skip: "Saltar",
    },
    submit_modal: {
      title: "¿Listo para enviar?",
      description:
        "No encontramos nada mal con tu archivo. ¡Estás listo para irte!",
      submit: "Sí, enviar",
      cancel: "Volver",
    },
    confirm_close_modal: {
      title: "¿Estás seguro?",
      description:
        "Cerrar el importador hará que se pierdan todos los cambios.",
      close: "Cerrar",
      cancel: "Cancelar",
    },
    preview_mode_banner: {
      title: "Actualmente estás en modo de vista previa.",
    },
    warnings_banner: {
      trial_mode: {
        info_text:
          "Esta cuenta está en modo de prueba. Las importaciones están limitadas a 100 registros.",
        button_text: "Seleccionar plan",
      },
      invalid_submit_fn: {
        info_text:
          "Enviar está desactivado. Desactive el modo incógnito o agregue un hook onSubmit para habilitar el envío.",
        button_text: "¿Cómo hacerlo?",
      },
    },
  },
};
