import { MutableRefObject } from "react";
import {
  DuplicateValuesCollection,
  Field,
  FieldValidation,
  OnValidateRecord,
  Record,
  ValidationErrors,
  ValidationResult,
} from "fuse-importer";
import {
  ignoreNullOrUndefinedKeyValues,
  isEmpty,
  isNullOrUndefined,
  isRecordEmpty,
} from "../common";
import { FieldValidator, fieldValidations } from "./validators";

type SelectedValidator = {
  validator: FieldValidation;
  validatorFunction: FieldValidator;
};

type SelectedFieldValidators = {
  field: Field;
  validators: SelectedValidator[];
};

type ValidateRecordArgs = {
  onValidateRecord?: OnValidateRecord;
  validatorsCache?: SelectedFieldValidators[];
  record: Record;
  fields: Field[];
  duplicateValues: MutableRefObject<DuplicateValuesCollection>;
  backendErrors?: ValidationErrors;
  backendWarnings?: ValidationErrors;
};

type SafeCallValidatorArgs = SelectedValidator & {
  field: Field;
  record: Record;
  duplicateValues: MutableRefObject<DuplicateValuesCollection>;
};

type RunValidatorsForArgs = {
  record: Record;
  validators: SelectedFieldValidators[];
  duplicateValues: MutableRefObject<DuplicateValuesCollection>;
};

type RunValidatorsForReturned = {
  errors: { [key: string]: string };
  warnings: { [key: string]: string };
};

const datePatternTypes = ["date", "datetime", "time"];

async function safeCallOnValidateRecord({
  onValidateRecord,
  record,
}: Pick<ValidateRecordArgs, "onValidateRecord" | "record">) {
  try {
    const result = await onValidateRecord(record);
    const isNewVersion = "errors" in result || "warnings" in result;
    const mappedResult: any = isNewVersion
      ? (result as ValidationResult)
      : (result as ValidationErrors);

    const errors: ValidationErrors = isNewVersion
      ? (mappedResult.errors as ValidationErrors)
      : mappedResult;
    const warnings: ValidationErrors = isNewVersion
      ? (mappedResult.warnings as ValidationErrors)
      : mappedResult;

    return { errors: errors || {}, warnings: warnings || {} };
  } catch (error) {
    console.warn(`onValidateRecord hook failed with error: ${error}`);
    return { errors: {}, warnings: {} } as ValidationResult;
  }
}

function safeCallValidator({
  field,
  record,
  validator,
  validatorFunction,
  duplicateValues,
}: SafeCallValidatorArgs) {
  try {
    return validatorFunction(
      field,
      record[field.name],
      validator,
      duplicateValues
    );
  } catch (e) {
    console.error(
      `Validator ${validator?.validation_type} for field ${JSON.stringify(
        field
      )} and value ${record[field.name]} failed.`,
      e
    );
    return `${e}`;
  }
}

type Error = {
  [key: string]: string;
};

function mergeErrorsAndWarnings(
  currentErrors: RunValidatorsForReturned,
  errors: Error
) {
  return {
    ...currentErrors,
    errors: { ...(currentErrors.errors || {}), ...errors },
    warnings: { ...(currentErrors.warnings || {}) },
  };
}

function runValidatorsFor({
  record,
  validators,
  duplicateValues,
}: RunValidatorsForArgs) {
  return validators.reduce((currentErrors, { field, validators }) => {
    const errors: Error = {};

    if (!field.isRequired && isEmpty(record[field.name])) {
      return mergeErrorsAndWarnings(currentErrors, errors);
    }

    for (let index = 0; index < validators.length; index++) {
      const { validator, validatorFunction } = validators[index];
      const error = safeCallValidator({
        field,
        record,
        validator,
        validatorFunction,
        duplicateValues,
      });
      if (error) {
        errors[field.name] = error;
        break;
      }
    }

    return mergeErrorsAndWarnings(currentErrors, errors);
  }, {} as RunValidatorsForReturned);
}

export function selectValidatorsFor(
  fields: Field[]
): SelectedFieldValidators[] {
  return fields.map((field) => {
    const defaultValidators: SelectedValidator[] = [];

    if (field.isRequired) {
      defaultValidators.push({
        validator: null,
        validatorFunction: fieldValidations.required,
      });
    }

    if (datePatternTypes.includes(field.type)) {
      defaultValidators.push({
        validator: null,
        validatorFunction: fieldValidations.date_pattern,
      });
    }

    const configuredValidators =
      field.validations?.map((validator) => {
        const validatorFunction = fieldValidations[validator.validation_type];

        if (!validatorFunction) {
          throw new Error(
            `Validator ${validator.validation_type} not implemented.`
          );
        }

        return {
          validator,
          validatorFunction,
        };
      }) ?? [];

    return {
      field,
      validators: [...defaultValidators, ...configuredValidators],
    };
  });
}

export async function validateRecord({
  record,
  fields,
  onValidateRecord,
  validatorsCache,
  duplicateValues,
  backendErrors,
  backendWarnings,
}: ValidateRecordArgs): Promise<{
  errors?: ValidationErrors;
  warnings?: ValidationErrors;
}> {
  if (isRecordEmpty(record, fields)) {
    //TODO: Check this logic because this can skip the validation of touched rows because all fields are empty
    return { errors: {}, warnings: {} };
  }

  if (backendErrors || backendWarnings) {
    return { errors: backendErrors || {}, warnings: backendWarnings || {} };
  }

  const validators = validatorsCache ?? selectValidatorsFor(fields);

  const {
    errors: validatorErrors,
    warnings: validatorWarnings,
  } = runValidatorsFor({
    record,
    validators,
    duplicateValues,
  });

  const errors = ignoreNullOrUndefinedKeyValues(validatorErrors);
  const warnings = ignoreNullOrUndefinedKeyValues(validatorWarnings);

  if (typeof onValidateRecord === "function") {
    const {
      errors: onValidateRecordErrors,
      warnings: onValidateRecordWarnings,
    } = await safeCallOnValidateRecord({
      onValidateRecord,
      record,
    });

    return {
      errors: { ...errors, ...onValidateRecordErrors },
      warnings: { ...warnings, ...onValidateRecordWarnings },
    };
  }

  return { errors, warnings };
}
