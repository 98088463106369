import { Field, FieldTypes, Record } from "fuse-importer";

export const spreadsheetRowHeightPx = 32;
export const spreadsheetHeaderHeightPx = 32;

export const isNullOrUndefined = (value) =>
  value === null || value === undefined;

export const isEmpty = (value: string) => {
  return (
    isNullOrUndefined(value) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" &&
      (value.length === 0 || value.trim().length === 0))
  );
};

export const isEmail = (value: string) => {
  const emailRegex = /^[\w+.-]+@([\w-]+\.)+[\w-]+$/;
  return emailRegex.test(value);
};

export const isUrl = (value: string) => {
  const urlRegex = /^(?:(?:https?|ftp|file|tcp|mailto|tel|data|ssh|irc|gopher|ws|wss):\/\/|www\.)[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,16}(:[0-9]{1,5})?(\/.*)?$/g;
  return urlRegex.test(value);
};

export const isTrue = (value: string): boolean => {
  const _value = value?.toString()?.toLowerCase();
  return ["true", "yes", "1", "y", "on", "t"].includes(_value);
};

export const isFalse = (value: string): boolean => {
  const _value = value?.toString()?.toLowerCase();
  return ["false", "no", "0", "n", "off", "f"].includes(_value);
};

export const isBoolean = (value: string): boolean => {
  return isTrue(value) || isFalse(value);
};

export const isInteger = (value: string) => /^-?[0-9]+$/.test(value + "");

export const isFloat = (value: string) => /^-?(?:\d+|\d*\.\d+)$/.test(value);

export const isEven = (value: number) => value % 2 === 0;

export const ignoreNullOrUndefinedKeyValues = (obj: any) => {
  const result = {};
  Object.keys(obj || {})
    .filter((key) => obj[key] !== null && obj[key] !== undefined)
    .forEach((key) => {
      result[key] = obj[key];
    });
  return result;
};

export const isRecordEmpty = (record: Record, fields: Field[]) => {
  const hasValue = fields.some((f) => !isEmpty(record[f.name]));
  return !hasValue;
};

export const parseValue = (
  fieldType: FieldTypes,
  value: any,
  enumSet?: any
) => {
  if (isEmpty(value)) return value;

  const cleanNumber = (val) => {
    // Remove all non-numeric characters except the decimal point.
    return typeof val === "string" ? val.replace(/[^\d.-]/g, "") : val;
  };

  if (fieldType === FieldTypes.float) {
    const cleanedValue = cleanNumber(value);
    return isFloat(cleanedValue) ? parseFloat(cleanedValue) : value;
  }
  if (fieldType === FieldTypes.integer) {
    const cleanedValue = cleanNumber(value);
    return isInteger(cleanedValue) ? parseInt(cleanedValue, 10) : value;
  }
  if (fieldType === FieldTypes.boolean) return isTrue(value) ? true : false;
  if (fieldType === FieldTypes.enum && enumSet) return enumSet[value];

  return value;
};
