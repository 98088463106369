import { chevronDownIcon, colors, Div, Divider } from "fuse-shared-ui";
import { DateTime } from "luxon";
import { useState } from "react";
import styled from "styled-components";
import { Pill, PillType } from "../../../../../../common/Pill";
import { CloseIcon } from "../../../../common/NavigationLayout/Navigation/icons";
import { LogModalProps } from "../interfaces";
import { formatContent } from "./ErrorDetails";
import {
  between,
  getErrorDetails,
  getResponseColor,
  translatedStatus,
} from "./functions";

export const Backdrop = styled(Div)`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

const LogDetailsContainer = styled(Div)`
  background: white;
  height: 100%;
  min-width: 416px;
  overflow-y: auto;
  padding: 32px 24px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;

  &,
  & * {
    box-sizing: border-box;
  }

  ${({ theme }) => theme.css.scrollbarDark};

  @media all and (${({ theme }) => theme.media.xs}) {
    min-width: auto;
    width: 100%;
  }
`;

const TopArea = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media all and (${({ theme }) => theme.media.xs}) {
    gap: 32px;
  }
`;

const ModalTitle = styled(Div)`
  ${({ theme }) => theme.textStyles.h5};

  @media all and (${({ theme }) => theme.media.xs}) {
    ${({ theme }) => theme.textStyles.h6};
  }
`;

const BottomArea = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const ViewErrorDetails = styled(Div)<{ isOpen: boolean }>`
  ${({ theme }) => theme.textStyles.buttonText}

  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  user-select: none;
  width: max-content;

  svg {
    rotate: ${({ isOpen }) => isOpen && "180deg"};
    transition: all 0.3s;
  }
`;

const ErrorDetails = styled(Div)`
  background: #313131;
  box-sizing: border-box;
  color: #ffffff;
  max-height: 344px;
  max-width: 368px;
  overflow-y: auto;
  padding: 24px;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${({ theme }) => theme.css.scrollbarDark};
  ${({ theme }) => theme.textStyles.body3};

  pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  @media all and (${({ theme }) => theme.media.xs}) {
    max-width: 100%;
  }
`;

const Information = styled(Div)`
  ${({ theme }) => theme.textStyles.body1};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LogModal = ({ log, onClose }: LogModalProps) => {
  const [expandErrorDetails, setExpandErrorDetails] = useState(false);

  const toggleErrorDetails = () => {
    setExpandErrorDetails((oldValue) => !oldValue);
  };

  if (!log) return <></>;

  const isSuccess =
    log?.exception_message === null && between(log?.response_status, 200, 299);

  const date = DateTime.fromISO(log.timestamp || "", { zone: "utc" });
  const formatted = `${date.toFormat("yyyy-MM-dd HH:mm:ss")} UTC`;

  const logErrorDetails = getErrorDetails(log);

  return (
    <>
      <Backdrop data-cy="log-details-modal-backdrop" onClick={onClose} />

      <LogDetailsContainer data-cy="log-details-modal">
        <TopArea>
          <Div dflex alignCenter spaceBetween>
            <ModalTitle>Log details</ModalTitle>

            <Div
              data-cy="close-detail-model"
              clickable
              dflex
              alignCenter
              onClick={onClose}
            >
              <CloseIcon />
            </Div>
          </Div>

          <Div dflex flexColumn gap={24}>
            <Div dflex gap={21}>
              <Div data-cy="status-label">
                <Div body2 bold>
                  Status
                </Div>

                <Pill
                  status={translatedStatus(isSuccess).status}
                  variant={translatedStatus(isSuccess).variant as PillType}
                />
              </Div>

              <Div data-cy="response-label">
                <Div body2 bold>
                  Response
                </Div>

                <Div wMaxContent>
                  <Pill
                    status={
                      log?.response_status === 0
                        ? "N/A"
                        : String(log?.response_status)
                    }
                    variant={getResponseColor(log?.response_status) as PillType}
                    customIcon={null}
                  />
                </Div>
              </Div>
            </Div>

            {!isSuccess && logErrorDetails && (
              <Div dflex flexColumn gap={12}>
                <ViewErrorDetails
                  data-cy="view-error-details"
                  onClick={toggleErrorDetails}
                  isOpen={expandErrorDetails}
                >
                  View error details {chevronDownIcon}
                </ViewErrorDetails>

                {expandErrorDetails && (
                  <ErrorDetails data-cy="error-details-under-log-detail-model">
                    <pre>{formatContent(logErrorDetails)}</pre>
                  </ErrorDetails>
                )}
              </Div>
            )}

            <Divider bgColor={colors.gray200} />
          </Div>
        </TopArea>

        <BottomArea>
          <Div>
            <Div body2 data-cy="url">
              URL
            </Div>
            <Information data-cy="url-info">{log?.url}</Information>
          </Div>

          <Div>
            <Div body2 data-cy="webhook-type">
              Type
            </Div>
            <Information data-cy="type-value">
              {log?.webhook?.webhook_type === "status"
                ? "Status Webhook"
                : "Record Webhook"}
            </Information>
          </Div>

          <Div>
            <Div body2 data-cy="timestamp">
              Timestamp
            </Div>
            <Information data-cy="timestamp-info">{formatted}</Information>
          </Div>

          <Div>
            <Div body2 data-cy="import-slug">
              Import slug
            </Div>
            <Information data-cy="import-slug-info">
              {log?.import_slug}
            </Information>
          </Div>

          <Div>
            <Div body2 data-cy="importer-name">
              Template
            </Div>
            <Information data-cy="importer-name-info">
              {log?.template_name}
            </Information>
          </Div>
        </BottomArea>
      </LogDetailsContainer>
    </>
  );
};
