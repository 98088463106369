import {
  Dropdown as DropdownBase,
  DropdownContainer,
} from "components/common/Dropdown";
import { Div, Divider } from "fuse-shared-ui";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import {
  ACCOUNT_ROUTE,
  API_CREDENTIALS_ROUTE,
  BILLING_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE,
} from "../../../..";
import { useUserContext } from "../../../../../../../UserContextProvider";
import { AvatarPlaceholder } from "../../../../../../../common/AvatarPlaceholder";
import { cashMoneyIcon, logOutIcon, settingsIcon } from "../../icons";
import { DropdownItem } from "./DropdownItem";
import { OrganizationDropdownItem } from "./OrganizationDropdownItem";

type DropdownDesktopUIProps = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDropdownOpen: boolean;
};

const Dropdown = styled(DropdownBase)`
  ${DropdownContainer} {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    overflow: visible;
    width: 320px;
  }

  border-radius: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);

  @media all and (${({ theme }) => theme.media.xs}) {
    position: fixed;
  }
`;

export const DropdownDesktopUI = ({
  isDropdownOpen,
  setIsDropdownOpen,
}: DropdownDesktopUIProps) => {
  const history = useHistory();
  const { user, signOut } = useUserContext();

  const handleItemClick = (route: string) => {
    setIsDropdownOpen(false);
    history.push(route);
  };

  return (
    <Dropdown
      placement={"right-start"}
      isOpen={isDropdownOpen}
      onClose={() => setIsDropdownOpen(false)}
      offset={[-15, 3]}
    >
      <Div p={16}>
        <OrganizationDropdownItem />
        <DropdownItem
          data-cy="org_setting"
          label="Organization Settings"
          icon={settingsIcon}
          onClick={() => handleItemClick(ORGANIZATION_SETTINGS_ROUTE)}
          isSelected={
            !!useRouteMatch({
              path: ORGANIZATION_SETTINGS_ROUTE,
              exact: true,
            })
          }
        />
        <DropdownItem
          data-cy="billingPlansText"
          label="Billing & Plans"
          icon={cashMoneyIcon}
          onClick={() => handleItemClick(BILLING_ROUTE)}
          isSelected={
            !!useRouteMatch({
              path: BILLING_ROUTE,
              exact: true,
            })
          }
        />
        <Divider bgColor="#E5DBDB" m="16px 0" />
        <DropdownItem
          label="My Account"
          icon={<AvatarPlaceholder user={user} />}
          onClick={() => handleItemClick(ACCOUNT_ROUTE)}
          isSelected={
            !!useRouteMatch({
              path: ACCOUNT_ROUTE,
              exact: true,
            })
          }
        />
        <Divider bgColor="#E5DBDB" m="16px 0" />
        <DropdownItem
          data-cy="logout"
          label="Log out"
          icon={logOutIcon}
          onClick={() => signOut()}
        />
      </Div>
    </Dropdown>
  );
};
